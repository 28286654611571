import React, { useState, useEffect } from "react";
import { Button, Row, Col, Container, Card, CardHeader } from "reactstrap";
import BackBtn from "utils/backBtn";
import Month0 from "./detailComponent/Month0";
import Month2And3 from "./detailComponent/Month2And3";
import Month5 from "./detailComponent/Month5";
import MonthEnd from "./detailComponent/MonthEnd";
import PresumptiveInfo from "./detailComponent/PresumptiveInfo";
import Remark from "./detailComponent/Remark";
import { connect } from "react-redux";
import { skipMonth } from "utils/tobaccoCondition";
import { getTownships, getNurseCounsellors } from "store/actions";
import { isSpecifiedTownship } from "utils/middleware";
import FinalOutcome from "./detailComponent/FinalOutcome";
import TreatmentOutcome from "./detailComponent/TreatmentOutcome";
import Supported from "./detailComponent/Supported";

const TransferredPatientDetail = ({
  auth,
  patient,
  getTownships,
  getNurseCounsellors,
}) => {
  const { role } = auth.user;
  const [page, setPage] = useState("Presumptive_Info");

  useEffect(() => {
    if (role !== undefined && !isSpecifiedTownship(role)) {
      getTownships();
    }
    getNurseCounsellors();
  }, [getTownships, getNurseCounsellors, role]);

  const handleShowPage = () => {
    switch (page) {
      case "Presumptive_Info":
        return <PresumptiveInfo />;
      case "Month_0":
        return <Month0 />;
      case "Month_2_And_3":
        return <Month2And3 />;
      case "Month_5":
        return <Month5 />;
      case "Month_End":
        return <MonthEnd />;
      case "Final_Outcome":
        return <FinalOutcome />;
      case "Support_Received":
        return <Supported />;
      case "Treatment_Outcome":
        return <TreatmentOutcome />;
      case "Remark":
        return <Remark />;
      default:
        return <PresumptiveInfo />;
    }
  };

  const handleColor = (currentPage, data) => {
    if (page === currentPage) {
      return "success";
    } else {
      if (data) {
        return "primary";
      } else {
        return "danger";
      }
    }
  };

  const {
    month_2_3_transfer_in_patient_this_month_smoking,
    month_2_3_status_of_exposure_to_smoking_inside_the_home,
    month_5_transfer_in_patient_this_month_smoking,
    month_5_status_of_exposure_to_smoking_inside_the_home,
    month_5_status_of_smoking_to_skip_transfer_in_patient,
  } = patient.patient;

  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Card>
        <CardHeader className="border-0">
          <Row className="mb-2">
            <Col>
              <Button
                color={page === "Presumptive_Info" ? "success" : "primary"}
                size="lg"
                onClick={() => setPage("Presumptive_Info")}
              >
                PATIENT INFO
              </Button>
              {/* <Button
                color={page === 'Month_0' ? 'success' : 'primary'}
                size="lg"
                onClick={() => setPage('Month_0')}
              >
                Month 0
              </Button> */}
              <Button
                color={handleColor(
                  "Month_2_And_3",
                  patient.patient.month_2_3_transfer_in
                )}
                size="lg"
                onClick={() => setPage("Month_2_And_3")}
              >
                Month 2/3
              </Button>
              {!skipMonth(
                month_2_3_transfer_in_patient_this_month_smoking,
                month_2_3_status_of_exposure_to_smoking_inside_the_home
              ) && (
                <>
                  <Button
                    color={handleColor(
                      "Month_5",
                      patient.patient.month_5_transfer_in ||
                        patient.patient.month_5_date
                    )}
                    size="lg"
                    onClick={() => setPage("Month_5")}
                    disabled={!patient.patient.month_2_3_transfer_in}
                  >
                    Month 5
                  </Button>
                  {!skipMonth(
                    month_2_3_transfer_in_patient_this_month_smoking,
                    month_5_status_of_exposure_to_smoking_inside_the_home
                  ) &&
                    !skipMonth(
                      month_5_transfer_in_patient_this_month_smoking,
                      month_5_status_of_exposure_to_smoking_inside_the_home
                    ) &&
                    month_5_status_of_smoking_to_skip_transfer_in_patient !==
                      "D" && (
                      <Button
                        color={handleColor(
                          "Month_End",
                          patient.patient.month_end_transfer_in ||
                            patient.patient.month_end_date
                        )}
                        size="lg"
                        onClick={() => setPage("Month_End")}
                        disabled={
                          !(
                            patient.patient.month_5_transfer_in ||
                            patient.patient.month_5_date
                          )
                        }
                      >
                        Month End
                      </Button>
                    )}
                </>
              )}
              <Button
                color={page === "Final_Outcome" ? "success" : "primary"}
                size="lg"
                onClick={() => setPage("Final_Outcome")}
              >
                Final Outcome
              </Button>
              {patient?.patient?.final_outcome === "Q" && (
                <Button
                  color={page === "Support_Received" ? "success" : "primary"}
                  size="lg"
                  onClick={() => setPage("Support_Received")}
                >
                  Support Received for Q Patient
                </Button>
              )}
              <Button
                color={page === "Treatment_Outcome" ? "success" : "primary"}
                size="lg"
                onClick={() => setPage("Treatment_Outcome")}
              >
                Treatment Outcome
              </Button>
              <Button
                color={page === "Remark" ? "success" : "primary"}
                size="lg"
                onClick={() => setPage("Remark")}
              >
                Remark
              </Button>
            </Col>
          </Row>
        </CardHeader>
        {handleShowPage()}
      </Card>
    </Container>
  );
};
const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  patient: store.patient,
});

export default connect(mapStateToProps, { getTownships, getNurseCounsellors })(
  TransferredPatientDetail
);
