import React from 'react'
import ReactBSAlert from 'react-bootstrap-sweetalert'

const CustomConfirmAlert = ({ onConfirm, onCancel, title, label }) => {
  return (
    <ReactBSAlert
      warning
      title={title}
      onConfirm={onConfirm}
      onCancel={onCancel}
      showCancel
      confirmBtnBsStyle="success"
      confirmBtnText="Yes, save it!"
      cancelBtnBsStyle="secondary"
      cancelBtnText="Cancel"
    >
      {label}
    </ReactBSAlert>
  )
}

export default CustomConfirmAlert
