import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { Button, Card, CardHeader, Container, Row, Col } from 'reactstrap'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import CustomPagination from 'components/Pagination/Pagination'
import CustomTable from 'components/Table/Table'
import { connect } from 'react-redux'
import {
  getNurseCounsellors,
  getTownships,
  deleteNurseCounsellor,
  recoverNurseCounsellor,
} from 'store/actions'
import FullScreenLoading from 'components/FullScreenLoading/FullScreenLoading'
import { paginationLimit } from 'variables/limits'
import { isSpecifiedTownship } from 'utils/middleware'
import BackBtn from 'utils/backBtn'
import CustomAlert from 'components/Alert/CustomAlert'

const TrashNurseCounsellorList = ({
  status,
  auth,
  nurseCounsellor,
  township,
  getNurseCounsellors,
  getTownships,
  deleteNurseCounsellor,
  recoverNurseCounsellor,
}) => {
  const { role } = auth.user
  const router = useHistory()

  const [alert, setAlert] = useState(null)
  const [selectedTownship, setSelectedTownship] = useState(null)

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }))

  const handleFilter = () => {
    const query = queryString.parse(router.location.search)
    query.page = 1
    if (selectedTownship) {
      query.township_id = selectedTownship.value
    } else {
      delete query['township_id']
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`)
  }

  useEffect(() => {
    const query = queryString.parse(router.location.search)
    query.status = 0
    if (!('page' in query)) {
      query.page = 1
    }
    getNurseCounsellors(query)
  }, [getNurseCounsellors, router.location.search])

  useEffect(() => {
    if (role !== undefined && !isSpecifiedTownship(role)) {
      getTownships()
    }
  }, [getTownships, role])

  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={() => {
          deleteNurseCounsellor(id)
          setAlert(null)
        }}
        onCancel={() => setAlert(null)}
      />,
    )
  }

  const handleRecover = async (id) => {
    await recoverNurseCounsellor(id)
  }

  if (status.loading) {
    return <FullScreenLoading />
  }

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Trash Nurse Counsellors</h3>
              </Col>
            </Row>
            <Row>
              {!isSpecifiedTownship(role) && (
                <>
                  <Col sm={4}>
                    <Select
                      className="mt-3"
                      options={townships}
                      value={selectedTownship}
                      onChange={(value) => setSelectedTownship(value)}
                      placeholder="Filter by township..."
                      isSearchable={true}
                      isClearable={true}
                    />
                  </Col>
                  <Col sm={4}>
                    <Button
                      size="sm"
                      color="success"
                      onClick={handleFilter}
                      className="mt-3"
                    >
                      <i className="fa fa-search" />
                    </Button>
                  </Col>
                </>
              )}
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th className="fixed-cell left" scope="col">
                  ID
                </th>
                <th className="fixed-cell left" scope="col">
                  Name
                </th>
                <th scope="col">Code</th>
                <th scope="col">Password</th>
                <th scope="col">Address</th>
                <th scope="col">Phone</th>
                <th scope="col">Township</th>
                <th scope="col">Action</th>
              </tr>
            }
            body={nurseCounsellor.nurseCounsellors.map(
              (nurseCounsellor, index) => (
                <tr key={nurseCounsellor.id}>
                  <td className="fixed-cell left">{index + 1}</td>
                  <td className="fixed-cell left">{nurseCounsellor.name}</td>
                  <td>{nurseCounsellor.code}</td>
                  <td>{nurseCounsellor.plain_password}</td>
                  <td>{nurseCounsellor.address}</td>
                  <td>{nurseCounsellor.phone}</td>
                  <td>{nurseCounsellor.township.name}</td>
                  <td>
                    <>
                      <Button
                        size="sm"
                        color="success"
                        onClick={() => handleRecover(nurseCounsellor.id)}
                      >
                        Recover
                      </Button>
                      <Button
                        size="sm"
                        color="danger"
                        onClick={() => handleDelete(nurseCounsellor.id)}
                      >
                        Delete
                      </Button>
                    </>
                  </td>
                </tr>
              ),
            )}
          />

          {nurseCounsellor.total > paginationLimit && (
            <CustomPagination
              pageCount={nurseCounsellor.total / paginationLimit}
            />
          )}
        </Card>
      </Container>
    </>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  township: store.township,
  nurseCounsellor: store.nurseCounsellor,
})

export default connect(mapStateToProps, {
  getNurseCounsellors,
  getTownships,
  deleteNurseCounsellor,
  recoverNurseCounsellor,
})(TrashNurseCounsellorList)
