import CustomDateInput from "components/Inputs/CustomDateInput";
import CustomSelect from "components/Inputs/CustomSelect";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
} from "reactstrap";
import { currentDate } from "utils/currentDate";
import { statusOfSmokingForThisFollowUpToSkipForTIPatient } from "variables/options";
import { timeAfterWakingToFirstCigarette } from "variables/options";
import { yesOrNo } from "variables/options";
import { followUpOption } from "variables/options";
import { patientMiddleware, UPDATE } from "utils/middleware";
import { connect } from "react-redux";
import { updatePatient, clearMonthPatient } from "../../../../../store/actions";
import CustomConfirmAlert from "components/Alert/CustomConfirmAlert";
import { signPersonTypeOptions } from "variables/options";
import CustomInput from "components/Inputs/CustomInput";
import { cigaTypeOptions } from "variables/options";

const MonthEnd = ({ auth, patient, updatePatient, clearMonthPatient }) => {
  const { role } = auth.user;

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedFollowUpOption, setSelectedFollowUpOption] = useState(null);
  const [selectedTransfer, setSelectedTransfer] = useState(null);
  const [
    selectedOnlyNeedToFillForPatientWhoWasTransferredInThisMonthSmoking,
    setSelectedOnlyNeedToFillForPatientWhoWasTransferredInThisMonthSmoking,
  ] = useState(null);
  const [
    selectedTimeAfterWakingToFirstCigarette,
    setSelectedTimeAfterWakingToFirstCigarette,
  ] = useState(null);
  const [
    selectedStatusOfSmokingForThisFollowUpToSkipForTIPatient,
    setSelectedStatusOfSmokingForThisFollowUpToSkipForTIPatient,
  ] = useState(null);
  const [
    selectedStatusOfExposureToSmokingInsideTheHome,
    setSelectedStatusOfExposureToSmokingInsideTheHome,
  ] = useState(null);
  const [selectedBriefAdvice, setSelectedBriefAdvice] = useState(null);
  const [selectedCessationSupport, setSelectedCessationSupport] =
    useState(null);
  const [
    selectedPatientRequestForNRTService,
    setSelectedPatientRequestForNRTService,
  ] = useState(null);
  const [selectedSignPersonType, setSelectedSignPersonType] = useState(null);
  const [selectedCigaTypes, setSelectedCigaTypes] = useState(null);

  useEffect(() => {
    const {
      month_end_transfer_in,
      month_end_date,
      month_end_option,
      month_end_transfer_in_patient_this_month_smoking,
      month_end_time_after_waking_to_first_cigarette,
      month_end_status_of_smoking_to_skip_transfer_in_patient,
      month_end_status_of_exposure_to_smoking_inside_the_home,
      month_end_brief_advice,
      month_end_cessation_support,
      month_end_patient_request_for_NRT_service,
      month_end_sign_person_type,
      month_end_sign_person_type_other,
      month_end_ciga_types,
    } = patient.patient;
    setValue(
      "month_end_transfer_in",
      month_end_transfer_in === null
        ? null
        : { value: month_end_transfer_in, label: month_end_transfer_in }
    );
    setSelectedTransfer(
      month_end_transfer_in === null
        ? null
        : {
            value: month_end_transfer_in,
            label: month_end_transfer_in,
          }
    );
    setValue("month_end_date", month_end_date);
    setValue(
      "month_end_sign_person_type_other",
      month_end_sign_person_type_other
    );
    setValue(
      "month_end_sign_person_type",
      month_end_sign_person_type === null
        ? null
        : {
            value: month_end_sign_person_type,
            label: month_end_sign_person_type,
          }
    );
    setSelectedSignPersonType(
      month_end_sign_person_type === null
        ? null
        : {
            value: month_end_sign_person_type,
            label: month_end_sign_person_type,
          }
    );

    setValue(
      "month_end_option",
      month_end_option === null
        ? null
        : {
            value: month_end_option,
            label: month_end_option,
          }
    );
    setSelectedFollowUpOption(
      month_end_option === null
        ? null
        : {
            value: month_end_option,
            label: month_end_option,
          }
    );
    setValue(
      "month_end_transfer_in_patient_this_month_smoking",
      month_end_transfer_in_patient_this_month_smoking === null
        ? null
        : {
            value: month_end_transfer_in_patient_this_month_smoking,
            label: month_end_transfer_in_patient_this_month_smoking,
          }
    );
    setSelectedOnlyNeedToFillForPatientWhoWasTransferredInThisMonthSmoking(
      month_end_transfer_in_patient_this_month_smoking === null
        ? null
        : {
            value: month_end_transfer_in_patient_this_month_smoking,
            label: month_end_transfer_in_patient_this_month_smoking,
          }
    );
    setValue(
      "month_end_time_after_waking_to_first_cigarette",
      month_end_time_after_waking_to_first_cigarette === null
        ? null
        : {
            value: month_end_time_after_waking_to_first_cigarette,
            label: month_end_time_after_waking_to_first_cigarette,
          }
    );
    setSelectedTimeAfterWakingToFirstCigarette(
      month_end_time_after_waking_to_first_cigarette === null
        ? null
        : {
            value: month_end_time_after_waking_to_first_cigarette,
            label: month_end_time_after_waking_to_first_cigarette,
          }
    );
    setValue(
      "month_end_status_of_smoking_to_skip_transfer_in_patient",
      month_end_status_of_smoking_to_skip_transfer_in_patient === null
        ? null
        : {
            value: month_end_status_of_smoking_to_skip_transfer_in_patient,
            label: month_end_status_of_smoking_to_skip_transfer_in_patient,
          }
    );
    setSelectedStatusOfSmokingForThisFollowUpToSkipForTIPatient(
      month_end_status_of_smoking_to_skip_transfer_in_patient === null
        ? null
        : {
            value: month_end_status_of_smoking_to_skip_transfer_in_patient,
            label: month_end_status_of_smoking_to_skip_transfer_in_patient,
          }
    );
    setValue(
      "month_end_status_of_exposure_to_smoking_inside_the_home",
      month_end_status_of_exposure_to_smoking_inside_the_home === null
        ? null
        : {
            value: month_end_status_of_exposure_to_smoking_inside_the_home,
            label: month_end_status_of_exposure_to_smoking_inside_the_home,
          }
    );
    setSelectedStatusOfExposureToSmokingInsideTheHome(
      month_end_status_of_exposure_to_smoking_inside_the_home === null
        ? null
        : {
            value: month_end_status_of_exposure_to_smoking_inside_the_home,
            label: month_end_status_of_exposure_to_smoking_inside_the_home,
          }
    );
    setValue(
      "month_end_brief_advice",
      month_end_brief_advice === null
        ? null
        : {
            value: month_end_brief_advice,
            label: month_end_brief_advice,
          }
    );
    setSelectedBriefAdvice(
      month_end_brief_advice === null
        ? null
        : {
            value: month_end_brief_advice,
            label: month_end_brief_advice,
          }
    );
    setValue(
      "month_end_cessation_support",
      month_end_cessation_support === null
        ? null
        : {
            value: month_end_cessation_support,
            label: month_end_cessation_support,
          }
    );
    setSelectedCessationSupport(
      month_end_cessation_support === null
        ? null
        : {
            value: month_end_cessation_support,
            label: month_end_cessation_support,
          }
    );
    setValue(
      "month_end_patient_request_for_NRT_service",
      month_end_patient_request_for_NRT_service === null
        ? null
        : {
            value: month_end_patient_request_for_NRT_service,
            label: month_end_patient_request_for_NRT_service,
          }
    );
    setSelectedPatientRequestForNRTService(
      month_end_patient_request_for_NRT_service === null
        ? null
        : {
            value: month_end_patient_request_for_NRT_service,
            label: month_end_patient_request_for_NRT_service,
          }
    );

    const cigaTypes = month_end_ciga_types?.split(" | ").map((value) => {
      return {
        value: value,
        label: value,
      };
    });
    setSelectedCigaTypes(cigaTypes);
    setValue("month_end_ciga_types", cigaTypes);
  }, [patient.patient, setValue]);

  const onSubmit = async (data) => {
    setLoading(true);
    await updatePatient(
      patient.patient?.id,
      {
        month_end_transfer_in: selectedTransfer?.value,
        month_end_sign_person_type: patient?.patient?.month_end_signature
          ? selectedSignPersonType?.value
          : null,
        month_end_sign_person_type_other:
          selectedSignPersonType?.value === "Family" ||
          selectedSignPersonType?.value === "Other"
            ? data.month_end_sign_person_type_other
            : null,
        month_end_date:
          selectedTransfer?.value === "No" ? null : data.month_end_date,
        month_end_option:
          selectedTransfer?.value === "No"
            ? null
            : selectedFollowUpOption?.value,
        month_end_transfer_in_patient_this_month_smoking:
          selectedTransfer?.value === "No"
            ? null
            : selectedOnlyNeedToFillForPatientWhoWasTransferredInThisMonthSmoking?.value,
        month_end_ciga_types:
          selectedTransfer?.value === "No"
            ? null
            : selectedCigaTypes?.map((el) => el.value).join(" | "),
        month_end_time_after_waking_to_first_cigarette:
          selectedTransfer?.value === "No"
            ? null
            : selectedTimeAfterWakingToFirstCigarette?.value,
        month_end_status_of_smoking_to_skip_transfer_in_patient:
          selectedTransfer?.value === "No"
            ? null
            : selectedStatusOfSmokingForThisFollowUpToSkipForTIPatient?.value,
        month_end_status_of_exposure_to_smoking_inside_the_home:
          selectedTransfer?.value === "No"
            ? null
            : selectedStatusOfExposureToSmokingInsideTheHome?.value,
        month_end_brief_advice:
          selectedTransfer?.value === "No" ? null : selectedBriefAdvice?.value,
        month_end_cessation_support:
          selectedTransfer?.value === "No"
            ? null
            : selectedCessationSupport?.value,
        month_end_patient_request_for_NRT_service:
          selectedTransfer?.value === "No"
            ? null
            : selectedPatientRequestForNRTService?.value,
      },
      "month-end",
      "Month End data"
    );
    setLoading(false);
  };

  const handleClear = () => {
    setAlert(
      <CustomConfirmAlert
        onConfirm={async () => {
          setAlert(null);
          setLoading(true);
          await clearMonthPatient(
            patient.patient.id,
            "clear-month-end",
            "Month End"
          );
          setLoading(false);
        }}
        onCancel={() => setAlert(null)}
        title="Month End Data Clear"
        label="Are you sure?"
      />
    );
  };

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <CardHeader className="border-0">
              <h3>TB Patient(TI) Month End</h3>
            </CardHeader>
            <CardBody>
              {patient.patient.month_end_signature && (
                <Row>
                  <Col md={12}>
                    <img
                      src={patient.patient.month_end_signature}
                      alt="Month end Sign"
                      width={150}
                      height={130}
                    />
                  </Col>

                  <Col md={6}>
                    <CustomSelect
                      id="month_end_sign_person_type"
                      label="Sign person type"
                      control={control}
                      rules={{
                        required: false,
                      }}
                      options={signPersonTypeOptions}
                      value={selectedSignPersonType}
                      setData={setSelectedSignPersonType}
                      placeholder="Sign person type"
                      isRequired={false}
                    />
                  </Col>
                  {selectedSignPersonType?.value === "Other" && (
                    <Col md={6}>
                      <CustomInput
                        id="month_end_sign_person_type_other"
                        label="Other sign person type"
                        register={{
                          ...register("month_end_sign_person_type_other", {
                            required: true,
                          }),
                        }}
                        placeholder="Enter Other Type"
                        errors={errors}
                        isRequired={true}
                      />
                    </Col>
                  )}
                </Row>
              )}
              {patient.patient?.month_2_3_transfer_in === "No" &&
                patient.patient?.month_5_transfer_in === "No" && (
                  <Row>
                    <Col md={4}>
                      <CustomSelect
                        id="month_end_transfer_in"
                        label="Transfer In"
                        rules={{ required: "Transfer In is required!" }}
                        control={control}
                        options={yesOrNo}
                        value={selectedTransfer}
                        setData={setSelectedTransfer}
                        placeholder="Transfer"
                      />
                    </Col>
                  </Row>
                )}
              {(selectedTransfer?.value === "Yes" ||
                patient.patient?.month_2_3_transfer_in === "Yes" ||
                patient.patient?.month_5_transfer_in === "Yes") && (
                <>
                  <Row>
                    {(patient.patient?.month_2_3_transfer_in === "Yes" ||
                      patient.patient?.month_5_transfer_in === "Yes") && (
                      <Col md={4}>
                        <CustomDateInput
                          id="month_end_date"
                          label={
                            patient.patient?.month_5_transfer_in === "No"
                              ? "Transfer Date"
                              : "Follow Up Date"
                          }
                          register={{
                            ...register("month_end_date", {
                              required:
                                patient.patient?.month_5_transfer_in === "No"
                                  ? "Transfer Date is required!"
                                  : "Follow Up Date is required!",
                            }),
                          }}
                          placeholder={
                            patient.patient?.month_5_transfer_in === "No"
                              ? "Select Transfer Date"
                              : "Select Follow Up Date"
                          }
                          errors={errors}
                          isRequired={true}
                          min={patient.patient?.date_of_registration}
                          max={currentDate()}
                        />
                      </Col>
                    )}
                    {(patient.patient?.month_2_3_transfer_in === "Yes" ||
                      patient.patient?.month_5_transfer_in === "Yes") && (
                      <Col md={4}>
                        <CustomSelect
                          id="month_end_option"
                          label=" Option"
                          control={control}
                          rules={{
                            required: "Follow Up Option",
                          }}
                          options={followUpOption}
                          value={selectedFollowUpOption}
                          setData={setSelectedFollowUpOption}
                          placeholder=" Option"
                          isRequired={true}
                        />
                      </Col>
                    )}
                  </Row>
                  {patient.patient?.month_2_3_transfer_in === "No" &&
                  patient.patient?.month_5_transfer_in === "No" ? (
                    <>
                      <Row>
                        <Col md={10}>
                          <CustomSelect
                            id="month_end_transfer_in_patient_this_month_smoking"
                            label="Smoking status"
                            control={control}
                            rules={{
                              required: "Smoking status",
                            }}
                            options={yesOrNo}
                            value={
                              selectedOnlyNeedToFillForPatientWhoWasTransferredInThisMonthSmoking
                            }
                            setData={
                              setSelectedOnlyNeedToFillForPatientWhoWasTransferredInThisMonthSmoking
                            }
                            placeholder="Smoking status"
                            isRequired={true}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <CustomSelect
                            id="month_end_ciga_types"
                            label="Month end smoking cigaratte types"
                            control={control}
                            rules={{
                              required:
                                selectedOnlyNeedToFillForPatientWhoWasTransferredInThisMonthSmoking?.value ===
                                "Yes"
                                  ? "Month end smoking cigaratte types is required!"
                                  : false,
                            }}
                            options={cigaTypeOptions}
                            value={selectedCigaTypes}
                            setData={setSelectedCigaTypes}
                            placeholder="Month end smoking cigaratte types"
                            isRequired={
                              selectedOnlyNeedToFillForPatientWhoWasTransferredInThisMonthSmoking?.value ===
                              "Yes"
                            }
                            isDisabled={
                              selectedOnlyNeedToFillForPatientWhoWasTransferredInThisMonthSmoking ===
                                null ||
                              selectedOnlyNeedToFillForPatientWhoWasTransferredInThisMonthSmoking?.value !==
                                "Yes"
                            }
                            isClearable={true}
                            isMulti={true}
                          />
                        </Col>
                        <Col md={6}>
                          <CustomSelect
                            id="month_end_time_after_waking_to_first_cigarette"
                            label="Time after waking to first cigarette"
                            control={control}
                            rules={{
                              required:
                                selectedOnlyNeedToFillForPatientWhoWasTransferredInThisMonthSmoking?.value ===
                                "Yes"
                                  ? "Time after waking to first cigarette is required!"
                                  : false,
                            }}
                            options={timeAfterWakingToFirstCigarette}
                            value={selectedTimeAfterWakingToFirstCigarette}
                            setData={setSelectedTimeAfterWakingToFirstCigarette}
                            placeholder="Time after waking to first cigarette"
                            isRequired={
                              selectedOnlyNeedToFillForPatientWhoWasTransferredInThisMonthSmoking?.value ===
                              "Yes"
                            }
                            isDisabled={
                              selectedOnlyNeedToFillForPatientWhoWasTransferredInThisMonthSmoking ===
                                null ||
                              selectedOnlyNeedToFillForPatientWhoWasTransferredInThisMonthSmoking?.value !==
                                "Yes"
                            }
                          />
                        </Col>
                      </Row>
                    </>
                  ) : (
                    patient.patient
                      .month_2_3_transfer_in_patient_this_month_smoking !==
                      "No" &&
                    patient.patient
                      .month_5_transfer_in_patient_this_month_smoking !==
                      "No" && (
                      <Row>
                        <Col md={6}>
                          <CustomSelect
                            id="month_end_status_of_smoking_to_skip_transfer_in_patient"
                            label="Status of smoking for this month"
                            control={control}
                            rules={{
                              required:
                                "Status of smoking for this month is required!",
                            }}
                            options={
                              statusOfSmokingForThisFollowUpToSkipForTIPatient
                            }
                            value={
                              selectedStatusOfSmokingForThisFollowUpToSkipForTIPatient
                            }
                            setData={
                              setSelectedStatusOfSmokingForThisFollowUpToSkipForTIPatient
                            }
                            placeholder="Status of smoking for this month"
                          />
                        </Col>
                      </Row>
                    )
                  )}

                  <Row>
                    {patient.patient
                      .month_2_3_status_of_exposure_to_smoking_inside_the_home !==
                      "No" &&
                      patient.patient
                        .month_5_status_of_exposure_to_smoking_inside_the_home !==
                        "No" && (
                        <Col md={6}>
                          <CustomSelect
                            id="month_end_status_of_exposure_to_smoking_inside_the_home"
                            label="Status of exposure to smoking inside the home"
                            control={control}
                            rules={{
                              required:
                                "Status of exposure to smoking inside the home is required!",
                            }}
                            options={yesOrNo}
                            value={
                              selectedStatusOfExposureToSmokingInsideTheHome
                            }
                            setData={
                              setSelectedStatusOfExposureToSmokingInsideTheHome
                            }
                            placeholder="Status of exposure to smoking inside the home"
                            isRequired={true}
                          />
                        </Col>
                      )}
                    <Col md={6}>
                      <CustomSelect
                        id="month_end_brief_advice"
                        label="Brief Advice"
                        control={control}
                        rules={{
                          required: "Brief Advice is required!",
                        }}
                        options={yesOrNo}
                        value={selectedBriefAdvice}
                        setData={setSelectedBriefAdvice}
                        placeholder="Brief Advice"
                        isRequired={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <CustomSelect
                        id="month_end_cessation_support"
                        label="Cessation Support"
                        control={control}
                        rules={{
                          required: "Cessation Support is required!",
                        }}
                        options={yesOrNo}
                        value={selectedCessationSupport}
                        setData={setSelectedCessationSupport}
                        placeholder="Cessation Support"
                        isRequired={true}
                      />
                    </Col>
                    <Col md={6}>
                      <CustomSelect
                        id="month_end_patient_request_for_NRT_service"
                        label="Patient request for NRT service"
                        control={control}
                        rules={{
                          required:
                            "Patient request for NRT service is required!",
                        }}
                        options={yesOrNo}
                        value={selectedPatientRequestForNRTService}
                        setData={setSelectedPatientRequestForNRTService}
                        placeholder="Patient request for NRT service"
                        isRequired={true}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </CardBody>
            {patientMiddleware(role, UPDATE) && (
              <CardFooter className="text-right">
                <Button
                  color="primary"
                  size="sm"
                  disabled={loading}
                  type="submit"
                >
                  {loading ? "Loading" : "Update"}
                </Button>
                <Button
                  color="warning"
                  size="sm"
                  disabled={loading}
                  onClick={handleClear}
                >
                  {loading ? "Loading" : "Clear"}
                </Button>
              </CardFooter>
            )}
          </Card>
        </Form>
      </Container>
    </>
  );
};
const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  patient: store.patient,
});

export default connect(mapStateToProps, {
  updatePatient,
  clearMonthPatient,
})(MonthEnd);
