import CustomTextArea from 'components/Inputs/CustomTextArea'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
CardFooter,
} from 'reactstrap'
import { patientMiddleware } from 'utils/middleware'
import { UPDATE } from 'utils/middleware'
import { connect } from 'react-redux'
import { updatePatient } from '../../../../../store/actions'

const Remark = ({ auth, patient, updatePatient }) => {
  const { role } = auth.user
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const { remark } = patient.patient
    setValue('remark', remark)
  }, [patient.patient, setValue])

  const onSubmit = async (data) => {
    setLoading(true)
    await updatePatient(
      patient.patient?.id,
      {
        remark: data.remark,
      },
      'remark',
      'Remark',
    )
    setLoading(false)
  }

  return (
    <Container className="mt-3" fluid>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardHeader className="border-0">
            <h3>TB Patient Remark</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={6}>
                <CustomTextArea
                  id="remark"
                  label="Remark"
                  register={{
                    ...register('remark'),
                  }}
                  placeholder="Enter Remark"
                  errors={errors}
                  isRequired={false}
                />
              </Col>
            </Row>
          </CardBody>
          {patientMiddleware(role, UPDATE) && (
            <CardFooter className="text-right">
              <Button
                color="primary"
                size="sm"
                disabled={loading}
                type="submit"
              >
                {loading ? 'Loading' : 'Update'}
              </Button>
            </CardFooter>
          )}
        </Card>
      </Form>
    </Container>
  )
}
const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  patient: store.patient,
})

export default connect(mapStateToProps, { updatePatient })(Remark)
