export const SET_LOADING = 'SET_LOADING'
export const SET_SUCCESS = 'SET_SUCCESS'

export const SET_CURRENT_USER = 'SET_CURRENT_USER'

export const SHOW_ACCOUNTS = 'SHOW_ACCOUNTS'
export const SET_ACCOUNT = 'SET_ACCOUNT'
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT'
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT'
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT'

export const SHOW_REGIONS = 'SHOW_REGIONS'
export const SET_REGION = 'SET_REGION'
export const CREATE_REGION = 'CREATE_REGION'
export const UPDATE_REGION = 'UPDATE_REGION'
export const DELETE_REGION = 'DELETE_REGION'

export const SHOW_DISTRICTS = 'SHOW_DISTRICTS'
export const SET_DISTRICT = 'SET_DISTRICT'
export const CREATE_DISTRICT = 'CREATE_DISTRICT'
export const UPDATE_DISTRICT = 'UPDATE_DISTRICT'
export const DELETE_DISTRICT = 'DELETE_DISTRICT'

export const SHOW_TOWNSHIPS = 'SHOW_TOWNSHIPS'
export const SET_TOWNSHIP = 'SET_TOWNSHIP'
export const CREATE_TOWNSHIP = 'CREATE_TOWNSHIP'
export const UPDATE_TOWNSHIP = 'UPDATE_TOWNSHIP'
export const DELETE_TOWNSHIP = 'DELETE_TOWNSHIP'

export const SHOW_PATIENTS = 'SHOW_PATIENTS'
export const SHOW_FOLLOW_UP_PATIENTS = 'SHOW_FOLLOW_UP_PATIENTS'
export const SET_PATIENT = 'SET_PATIENT'
export const CREATE_PATIENT = 'CREATE_PATIENT'
export const UPDATE_PATIENT = 'UPDATE_PATIENT'
export const DELETE_PATIENT = 'DELETE_PATIENT'
export const SERIAL_NUMBER = 'SERIAL_NUMBER'

export const SHOW_NURSE_COUNSELLORS = 'SHOW_NURSE_COUNSELLORS'
export const SET_NURSE_COUNSELLOR = 'SET_NURSE_COUNSELLOR'
export const CREATE_NURSE_COUNSELLOR = 'CREATE_NURSE_COUNSELLOR'
export const UPDATE_NURSE_COUNSELLOR = 'UPDATE_NURSE_COUNSELLOR'
export const DELETE_NURSE_COUNSELLOR = 'DELETE_NURSE_COUNSELLOR'

export const SHOW_VOLUNTEER_HANDOUTS = 'SHOW_VOLUNTEER_HANDOUTS'
export const SET_VOLUNTEER_HANDOUT = 'SET_VOLUNTEER_HANDOUT'
export const CREATE_VOLUNTEER_HANDOUT = 'CREATE_VOLUNTEER_HANDOUT'
export const UPDATE_VOLUNTEER_HANDOUT = 'UPDATE_VOLUNTEER_HANDOUT'

export const SHOW_FORM_AND_REGISTERS = 'SHOW_FORM_AND_REGISTERS'
export const SET_FORM_AND_REGISTER = 'SET_FORM_AND_REGISTER'
export const CREATE_FORM_AND_REGISTER = 'CREATE_FORM_AND_REGISTER'
export const UPDATE_FORM_AND_REGISTER = 'UPDATE_FORM_AND_REGISTER'
export const DELETE_FORM_AND_REGISTER = 'DELETE_FORM_AND_REGISTER'

export const MONTHLY_REPORT = 'MONTHLY_REPORT'
export const OUTCOME_REPORT = 'OUTCOME_REPORT'
