import CustomSelect from "components/Inputs/CustomSelect";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
} from "reactstrap";
import { patientMiddleware, UPDATE } from "utils/middleware";
import { connect } from "react-redux";
import { timeAfterWakingToFirstCigarette } from "variables/options";
import { yesOrNo } from "variables/options";
import { updatePatient, clearMonthPatient } from "../../../../../store/actions";
import CustomConfirmAlert from "components/Alert/CustomConfirmAlert";
import { signPersonTypeOptions } from "variables/options";
import CustomInput from "components/Inputs/CustomInput";
import { cigaTypeOptions } from "variables/options";

const Month0 = ({ auth, patient, updatePatient, clearMonthPatient }) => {
  const { role } = auth.user;
  const {
    control,
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm();

  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedSmoking, setSelectedSmoking] = useState(null);
  const [
    selectedTimeAfterWakingToFirstCigarette,
    setSelectedTimeAfterWakingToFirstCigarette,
  ] = useState(null);
  const [
    selectedStatusOfExposureToSmokingInsideTheHome,
    setSelectedStatusOfExposureToSmokingInsideTheHome,
  ] = useState(null);
  const [selectedBriefAdvice, setSelectedBriefAdvice] = useState(null);
  const [selectedCessationSupport, setSelectedCessationSupport] =
    useState(null);
  const [
    selectedPatientRequestForNRTService,
    setSelectedPatientRequestForNRTService,
  ] = useState(null);
  const [selectedSignPersonType, setSelectedSignPersonType] = useState(null);
  const [selectedCigaTypes, setSelectedCigaTypes] = useState(null);

  useEffect(() => {
    const {
      month_0_smoking,
      month_0_time_after_waking_to_first_cigarette,
      month_0_status_of_exposure_to_smoking_inside_the_home,
      month_0_brief_advice,
      month_0_cessation_support,
      month_0_patient_request_for_NRT_service,
      month_0_sign_person_type,
      month_0_sign_person_type_other,
      month_0_ciga_types,
    } = patient.patient;
    setValue("month_0_sign_person_type_other", month_0_sign_person_type_other);
    setValue(
      "month_0_sign_person_type",
      month_0_sign_person_type === null
        ? null
        : { value: month_0_sign_person_type, label: month_0_sign_person_type }
    );
    setSelectedSignPersonType(
      month_0_sign_person_type === null
        ? null
        : {
            value: month_0_sign_person_type,
            label: month_0_sign_person_type,
          }
    );

    setValue(
      "month_0_smoking",
      month_0_smoking === null
        ? null
        : {
            value: month_0_smoking,
            label: month_0_smoking,
          }
    );
    setSelectedSmoking(
      month_0_smoking === null
        ? null
        : { value: month_0_smoking, label: month_0_smoking }
    );
    setValue(
      "month_0_time_after_waking_to_first_cigarette",
      month_0_time_after_waking_to_first_cigarette === null
        ? null
        : {
            value: month_0_time_after_waking_to_first_cigarette,
            label: month_0_time_after_waking_to_first_cigarette,
          }
    );
    setSelectedTimeAfterWakingToFirstCigarette(
      month_0_time_after_waking_to_first_cigarette === null
        ? null
        : {
            value: month_0_time_after_waking_to_first_cigarette,
            label: month_0_time_after_waking_to_first_cigarette,
          }
    );
    setValue(
      "month_0_status_of_exposure_to_smoking_inside_the_home",
      month_0_status_of_exposure_to_smoking_inside_the_home === null
        ? null
        : {
            value: month_0_status_of_exposure_to_smoking_inside_the_home,
            label: month_0_status_of_exposure_to_smoking_inside_the_home,
          }
    );
    setSelectedStatusOfExposureToSmokingInsideTheHome(
      month_0_status_of_exposure_to_smoking_inside_the_home === null
        ? null
        : {
            value: month_0_status_of_exposure_to_smoking_inside_the_home,
            label: month_0_status_of_exposure_to_smoking_inside_the_home,
          }
    );
    setValue(
      "month_0_brief_advice",
      month_0_brief_advice === null
        ? null
        : {
            value: month_0_brief_advice,
            label: month_0_brief_advice,
          }
    );
    setSelectedBriefAdvice(
      month_0_brief_advice === null
        ? null
        : {
            value: month_0_brief_advice,
            label: month_0_brief_advice,
          }
    );
    setValue(
      "month_0_cessation_support",
      month_0_cessation_support === null
        ? null
        : {
            value: month_0_cessation_support,
            label: month_0_cessation_support,
          }
    );
    setSelectedCessationSupport(
      month_0_cessation_support === null
        ? null
        : {
            value: month_0_cessation_support,
            label: month_0_cessation_support,
          }
    );
    setValue(
      "month_0_patient_request_for_NRT_service",
      month_0_patient_request_for_NRT_service === null
        ? null
        : {
            value: month_0_patient_request_for_NRT_service,
            label: month_0_patient_request_for_NRT_service,
          }
    );
    setSelectedPatientRequestForNRTService(
      month_0_patient_request_for_NRT_service === null
        ? null
        : {
            value: month_0_patient_request_for_NRT_service,
            label: month_0_patient_request_for_NRT_service,
          }
    );

    const cigaTypes = month_0_ciga_types?.split(" | ").map((value) => {
      return {
        value: value,
        label: value,
      };
    });
    setSelectedCigaTypes(cigaTypes);
    setValue("month_0_ciga_types", cigaTypes);
  }, [patient.patient, setValue]);

  const onSubmit = async (data) => {
    setLoading(true);
    await updatePatient(
      patient.patient?.id,
      {
        month_0_smoking: selectedSmoking.value,
        month_0_ciga_types:
          selectedSmoking?.value === "No"
            ? null
            : selectedCigaTypes?.map((el) => el.value).join(" | "),
        month_0_sign_person_type: patient?.patient?.month_0_signature
          ? selectedSignPersonType?.value
          : null,
        month_0_sign_person_type_other:
          selectedSignPersonType?.value === "Family" ||
          selectedSignPersonType?.value === "Other"
            ? data.month_0_sign_person_type_other
            : null,
        month_0_time_after_waking_to_first_cigarette:
          selectedTimeAfterWakingToFirstCigarette?.value,
        month_0_status_of_exposure_to_smoking_inside_the_home:
          selectedStatusOfExposureToSmokingInsideTheHome.value,
        month_0_brief_advice: selectedBriefAdvice.value,
        month_0_cessation_support: selectedCessationSupport.value,
        month_0_patient_request_for_NRT_service:
          selectedPatientRequestForNRTService.value,
      },
      "month-0",
      "Month 0 data"
    );
    setLoading(false);
  };

  const handleClear = () => {
    setAlert(
      <CustomConfirmAlert
        onConfirm={async () => {
          setAlert(null);
          setLoading(true);
          await clearMonthPatient(
            patient.patient.id,
            "clear-month-0",
            "Month 0"
          );
          setLoading(false);
        }}
        onCancel={() => setAlert(null)}
        title="Month 0 Data Clear"
        label="Are you sure?"
      />
    );
  };

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <CardHeader className="border-0">
              <h3>TB Patient Month 0</h3>
            </CardHeader>
            <CardBody>
              {patient.patient.month_0_signature && (
                <Row>
                  <Col md={12}>
                    <img
                      src={patient.patient.month_0_signature}
                      alt="Month 0 Sign"
                      width={150}
                      height={130}
                    />
                  </Col>

                  <Col md={6}>
                    <CustomSelect
                      id="month_0_sign_person_type"
                      label="Sign person type"
                      control={control}
                      rules={{
                        required: false,
                      }}
                      options={signPersonTypeOptions}
                      value={selectedSignPersonType}
                      setData={setSelectedSignPersonType}
                      placeholder="Sign person type"
                      isRequired={false}
                    />
                  </Col>
                  {selectedSignPersonType?.value === "Other" && (
                    <Col md={6}>
                      <CustomInput
                        id="month_0_sign_person_type_other"
                        label="Other sign person type"
                        register={{
                          ...register("month_0_sign_person_type_other", {
                            required: true,
                          }),
                        }}
                        placeholder="Enter Other Type"
                        errors={errors}
                        isRequired={true}
                      />
                    </Col>
                  )}
                </Row>
              )}
              <Row>
                <Col md={6}>
                  <CustomSelect
                    id="month_0_smoking"
                    label="Smoking"
                    control={control}
                    rules={{
                      required: "Smoking is required!",
                    }}
                    options={yesOrNo}
                    value={selectedSmoking}
                    setData={setSelectedSmoking}
                    placeholder="Smoking"
                    isRequired={true}
                  />
                </Col>
                <Col md={6}>
                  <CustomSelect
                    id="month_0_ciga_types"
                    label="Month 0 smoking cigaratte types"
                    control={control}
                    rules={{
                      required:
                         false,
                    }}
                    options={cigaTypeOptions}
                    value={selectedCigaTypes}
                    setData={setSelectedCigaTypes}
                    placeholder="Month 0 smoking cigaratte types"
                    isRequired={false}
                    isDisabled={
                      selectedSmoking === null ||
                      selectedSmoking?.value !== "Yes"
                    }
                    isClearable={true}
                    isMulti={true}
                  />
                </Col>
                <Col md={6}>
                  <CustomSelect
                    id="month_0_time_after_waking_to_first_cigarette"
                    label="Time after waking to first cigarette"
                    control={control}
                    rules={{
                      required:
                        selectedSmoking?.value === "Yes"
                          ? "Time after waking to first cigarette is required!"
                          : false,
                    }}
                    options={timeAfterWakingToFirstCigarette}
                    value={selectedTimeAfterWakingToFirstCigarette}
                    setData={setSelectedTimeAfterWakingToFirstCigarette}
                    placeholder="Time after waking to first cigarette"
                    isRequired={selectedSmoking?.value === "Yes"}
                    isDisabled={
                      selectedSmoking === null ||
                      selectedSmoking?.value !== "Yes"
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <CustomSelect
                    id="month_0_status_of_exposure_to_smoking_inside_the_home"
                    label="Status of exposure to smoking inside the home"
                    control={control}
                    rules={{
                      required:
                        "Status of exposure to smoking inside the home is required!",
                    }}
                    options={yesOrNo}
                    value={selectedStatusOfExposureToSmokingInsideTheHome}
                    setData={setSelectedStatusOfExposureToSmokingInsideTheHome}
                    placeholder="Status of exposure to smoking inside the home"
                    isRequired={true}
                  />
                </Col>
                <Col md={6}>
                  <CustomSelect
                    id="month_0_brief_advice"
                    label="Brief Advice"
                    control={control}
                    rules={{
                      required: "Brief Advice is required!",
                    }}
                    options={yesOrNo}
                    value={selectedBriefAdvice}
                    setData={setSelectedBriefAdvice}
                    placeholder="Brief Advice"
                    isRequired={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <CustomSelect
                    id="month_0_cessation_support"
                    label="Cessation Support"
                    control={control}
                    rules={{
                      required: "Cessation Support is required!",
                    }}
                    options={yesOrNo}
                    value={selectedCessationSupport}
                    setData={setSelectedCessationSupport}
                    placeholder="Cessation Support"
                    isRequired={true}
                  />
                </Col>
                <Col md={6}>
                  <CustomSelect
                    id="month_0_patient_request_for_NRT_service"
                    label="Patient request for NRT service"
                    control={control}
                    rules={{
                      required: "Patient request for NRT service is required!",
                    }}
                    options={yesOrNo}
                    value={selectedPatientRequestForNRTService}
                    setData={setSelectedPatientRequestForNRTService}
                    placeholder="Patient request for NRT service"
                    isRequired={true}
                  />
                </Col>
              </Row>
            </CardBody>
            {patientMiddleware(role, UPDATE) && (
              <CardFooter className="text-right">
                <Button
                  color="primary"
                  size="sm"
                  disabled={loading}
                  type="submit"
                >
                  {loading ? "Loading" : "Update"}
                </Button>
                {patient.patient.month_2_3_date === null &&
                  patient.patient.month_5_date === null &&
                  patient.patient.month_end_date === null && (
                    <Button
                      color="warning"
                      size="sm"
                      disabled={loading}
                      onClick={handleClear}
                    >
                      {loading ? "Loading" : "Clear"}
                    </Button>
                  )}
              </CardFooter>
            )}
          </Card>
        </Form>
      </Container>
    </>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  patient: store.patient,
});

export default connect(mapStateToProps, { updatePatient, clearMonthPatient })(
  Month0
);
