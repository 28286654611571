import React, { useEffect, useState } from "react";
import { Button, Card, CardHeader, Container, Row, Col } from "reactstrap";
import CustomTable from "components/Table/Table";
import { connect } from "react-redux";
import { getFormAndRegisters, getFormAndRegister } from "store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { handoutMiddleware, CREATE, UPDATE } from "utils/middleware";
import BackBtn from "utils/backBtn";
import FormAndRegisterCreate from "./FormAndRegisterCreate";
import FormAndRegisterUpdate from "./FormAndRegisterUpdate";
import { DELETE } from "utils/middleware";
import CustomAlert from "components/Alert/CustomAlert";
import { deleteFormAndRegister } from "store/actions";

const FormAndRegisterList = ({
  status,
  auth,
  formAndRegister,
  getFormAndRegister,
  getFormAndRegisters,
  deleteFormAndRegister,
}) => {
  const { role } = auth.user;
  const [alert, setAlert] = useState(null);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);

  useEffect(() => {
    getFormAndRegisters();
  }, [getFormAndRegisters]);

  if (status.loading) {
    return <FullScreenLoading />;
  }
  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={() => {
          deleteFormAndRegister(id);
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
      />
    );
  };

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Form And Register</h3>
              </Col>
              {handoutMiddleware(role, CREATE) && (
                <Col className="text-right" xs="6">
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => setIsCreateOpen(true)}
                  >
                    New
                  </Button>
                </Col>
              )}
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th scope="col">ID</th>
                <th scope="col">File Name</th>
                <th scope="col">Download Link</th>
                {handoutMiddleware(role, UPDATE) && <th scope="col">Action</th>}
              </tr>
            }
            body={formAndRegister.formAndRegisters.map(
              (formAndRegister, index) => (
                <tr key={formAndRegister.id}>
                  <td>{index + 1}</td>
                  <td>{formAndRegister.name}</td>
                  <td>
                    <a href={formAndRegister.file} target="_black">
                      Click Link To Download File
                    </a>
                  </td>
                  {/* {handoutMiddleware(role, UPDATE) && (
                    <td>
                      <Button
                        size="sm"
                        color="primary"
                        onClick={async () => {
                          await getFormAndRegister(formAndRegister.id)
                          setIsUpdateOpen(true)
                        }}
                      >
                        Update
                      </Button>
                    </td>
                  )} */}

                  {handoutMiddleware(role, DELETE) && (
                    <td>
                      <Button
                        size="sm"
                        color="danger"
                        onClick={() => handleDelete(formAndRegister.id)}
                      >
                        Delete
                      </Button>
                    </td>
                  )}
                </tr>
              )
            )}
          />
        </Card>
      </Container>

      <FormAndRegisterUpdate isOpen={isUpdateOpen} toggle={setIsUpdateOpen} />
      <FormAndRegisterCreate isOpen={isCreateOpen} toggle={setIsCreateOpen} />
    </>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  formAndRegister: store.formAndRegister,
});

export default connect(mapStateToProps, {
  getFormAndRegisters,
  getFormAndRegister,
  deleteFormAndRegister,
})(FormAndRegisterList);
