import {
  CREATE_NURSE_COUNSELLOR,
  DELETE_NURSE_COUNSELLOR,
  SET_NURSE_COUNSELLOR,
  SHOW_NURSE_COUNSELLORS,
  UPDATE_NURSE_COUNSELLOR,
} from '../type'

const initialState = {
  nurseCounsellors: [],
  nurseCounsellor: {},
  total: 0,
}

const nurseCounsellor = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_NURSE_COUNSELLORS:
      return {
        ...state,
        nurseCounsellors: action.payload.data,
        total: action.payload.total,
      }
    case CREATE_NURSE_COUNSELLOR:
      return {
        ...state,
        nurseCounsellors: [action.payload, ...state.nurseCounsellors],
        total: state.total + 1,
      }
    case DELETE_NURSE_COUNSELLOR:
      return {
        ...state,
        nurseCounsellors: state.nurseCounsellors.filter(
          (nurseCounsellor) => nurseCounsellor.id !== action.payload,
        ),
        total: state.total - 1,
      }
    case SET_NURSE_COUNSELLOR:
      return {
        ...state,
        nurseCounsellor: action.payload,
      }
    case UPDATE_NURSE_COUNSELLOR:
      if (
        JSON.stringify(state.nurseCounsellor) !== JSON.stringify(action.payload)
      ) {
        let clone = [...state.nurseCounsellors]
        let index = clone.findIndex(
          (nurseCounsellor) => nurseCounsellor.id === action.payload.id,
        )
        clone[index] = action.payload
        return {
          ...state,
          nurseCounsellors: clone,
          nurseCounsellor: action.payload,
        }
      } else {
        return state
      }
    default:
      return state
  }
}

export default nurseCounsellor
