import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Col, Row, Container } from "reactstrap";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { treatmentOutcomeOptions } from "variables/options";
import CustomSelect from "components/Inputs/CustomSelect";
import { updatePatient } from "../../../../../store/actions";
import { connect } from "react-redux";

const TreatmentOutcome = ({ patient, updatePatient }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [selectedTreatmentOutcome, setSelectedTreatmentOutcome] =
    useState(null);

  useEffect(() => {
    const { treatment_outcome, treatment_outcome_date } = patient.patient;
    setValue(
      "treatment_outcome",
      treatment_outcome
        ? { value: treatment_outcome, label: treatment_outcome }
        : null
    );
    setSelectedTreatmentOutcome(
      treatment_outcome
        ? { value: treatment_outcome, label: treatment_outcome }
        : null
    );
    setValue("treatment_outcome_date", treatment_outcome_date);
  }, [patient.patient, setValue]);

  const onSubmit = async (values) => {
    setLoading(true);
    await updatePatient(
      patient.patient.id,
      {
        treatment_outcome: values.treatment_outcome_date
          ? selectedTreatmentOutcome?.value
          : null,
        treatment_outcome_date: selectedTreatmentOutcome?.value
          ? values.treatment_outcome_date
          : null,
      },
      "treatment-outcome",
      "Treatment outcome"
    );
    setLoading(false);
  };

  const onClear = async (values) => {
    setLoading(true);
    await updatePatient(
      patient.patient.id,
      {
        treatment_outcome: null,
        treatment_outcome_date: null,
      },
      "treatment-outcome",
      "Treatment outcome"
    );
    setLoading(false);
  };
  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={4} className="mb-3">
            <CustomDateInput
              id="treatment_outcome_date"
              label="Outcome Date"
              register={{
                ...register("treatment_outcome_date", {
                  required: "Treatment outcome date is required",
                }),
              }}
              errors={errors}
              min="1950-01-01"
              max="2100-12-31"
            />
          </Col>

          <Col md={4}>
            <CustomSelect
              id="treatment_outcome"
              label="Treatment Outcome"
              rules={{
                required: "Treatment Outcome is required!",
              }}
              control={control}
              options={treatmentOutcomeOptions}
              value={selectedTreatmentOutcome}
              setData={setSelectedTreatmentOutcome}
              placeholder="Treatment Outcome"
            />
          </Col>
        </Row>
        <div className=" modal-footer  d-flex align-items-end col-12">
          <Button color="primary" type="submit" disabled={loading} size="sm">
            {loading ? "Loading" : "Save"}
          </Button>
          <Button color="danger" disabled={loading} onClick={onClear} size="sm">
            {loading ? "Loading" : "Clear"}
          </Button>
        </div>
      </Form>
    </Container>
  );
};

const mapStateToProps = (store) => ({
  patient: store.patient,
});

export default connect(mapStateToProps, { updatePatient })(TreatmentOutcome);
