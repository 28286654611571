import {
  CREATE_FORM_AND_REGISTER,
  DELETE_FORM_AND_REGISTER,
  SET_FORM_AND_REGISTER,
  SHOW_FORM_AND_REGISTERS,
  UPDATE_FORM_AND_REGISTER,
} from '../type'

const initialState = {
  formAndRegisters: [],
  formAndRegister: {},
}

const formAndRegister = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_FORM_AND_REGISTERS:
      return {
        ...state,
        formAndRegisters: action.payload,
      }
    case CREATE_FORM_AND_REGISTER:
      return {
        ...state,
        formAndRegisters: [action.payload, ...state.formAndRegisters],
      }
    case SET_FORM_AND_REGISTER:
      return {
        ...state,
        formAndRegister: action.payload,
      }
    case UPDATE_FORM_AND_REGISTER:
      if (
        JSON.stringify(state.formAndRegister) !== JSON.stringify(action.payload)
      ) {
        let clone = [...state.formAndRegisters]
        let index = clone.findIndex(
          (formAndRegister) => formAndRegister.id === action.payload.id,
        )
        clone[index] = action.payload
        return {
          ...state,
          formAndRegisters: clone,
          formAndRegister: action.payload,
        }
      } else {
        return state
      }
      case DELETE_FORM_AND_REGISTER:
        return {
          ...state,
          formAndRegisters: state.formAndRegisters.filter((form) => form.id !== action.payload),
        }
    default:
      return state
  }
}

export default formAndRegister
