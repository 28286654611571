import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { Container, Card, CardHeader, Row, Col, Button } from 'reactstrap'
import BackBtn from 'utils/backBtn'
import { years } from 'variables/options'
import styles from './OutcomeReport.module.css'
import { connect } from 'react-redux'
import { getTownships, getOutcomeReport } from 'store/actions'
import FullScreenLoading from 'components/FullScreenLoading/FullScreenLoading'
import { isSpecifiedTownship } from 'utils/middleware'
import { useHistory } from 'react-router'
import queryString from 'query-string'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
import classnames from 'classnames'
import { currentDate } from 'utils/currentDate'
import { outcomeMonths } from 'variables/options'

const OutcomeReport = ({
  status,
  auth,
  township,
  report,
  getTownships,
  getOutcomeReport,
}) => {
  const { role } = auth.user
  const router = useHistory()

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const [month, setMonth] = useState(null)
  const [year, setYear] = useState(null)
  const [selectedTownship, setSelectedTownship] = useState(null)

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }))

  useEffect(() => {
    if (role !== undefined && !isSpecifiedTownship(role)) {
      getTownships()
    }
  }, [getTownships, role])

  const handleFilter = () => {
    const query = queryString.parse(router.location.search)

    if (startDate !== '') {
      query.start_date = startDate
    } else {
      delete query['start_date']
    }

    if (endDate !== '') {
      query.end_date = endDate
    } else {
      delete query['end_date']
    }

    if (selectedTownship) {
      query.township_id = selectedTownship.value
    } else {
      delete query['township_id']
    }

    if (month) {
      query.month = month.value
    } else {
      delete query['month']
    }

    if (year) {
      query.year = year.value
    } else {
      delete query['year']
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`)
  }

  useEffect(() => {
    const query = queryString.parse(router.location.search)
    getOutcomeReport(query)
  }, [getOutcomeReport, router.location.search])

  if (status.loading) {
    return <FullScreenLoading />
  }

  let fileName = 'Outcome-Report'
  if (month) {
    fileName += `-${month.label}`
  }
  if (year) {
    fileName += `-${year.label}`
  }
  if (selectedTownship) {
    fileName += `-${selectedTownship.label}`
  }

  const {
    table_1_month_0_S,
    table_1_month_2_3_D,
    table_1_month_2_3_L,
    table_1_month_2_3_Q,
    table_1_month_2_3_R,
    table_1_month_2_3_S,
    table_1_month_5_D,
    table_1_month_5_L,
    table_1_month_5_Q,
    table_1_month_5_R,
    table_1_month_5_S,
    table_1_month_end_D,
    table_1_month_end_L,
    table_1_month_end_Q,
    table_1_month_end_R,
    table_1_month_end_S,
    table_2_month_0,
    table_2_month_end,
    table_3_1,
    table_3_2,
    table_3_3,
    table_3_4,
    table_3_5,
  } = report.outcomeReport

  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Card>
        <CardHeader className="border-0">
          <Row>
            <Col xs={6}>
              <h3 className="mb-0">Outcome Report</h3>
            </Col>
            <Col className="text-right" xs={6}>
              <Button size="sm" color="success" onClick={handleFilter}>
                <i className="fa fa-search" />
              </Button>
              <ReactHTMLTableToExcel
                className="btn btn-info btn-sm"
                table="table-to-xls"
                filename={fileName}
                sheet="Outcome-Report"
                buttonText="Export"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <label htmlFor="start_date">Start Date</label>
              <input
                id="start_date"
                className="form-control"
                type="date"
                max={currentDate()}
                value={startDate}
                onChange={(event) => setStartDate(event.target.value)}
              />
            </Col>
            <Col sm={4}>
              <label htmlFor="end_date">End Date</label>
              <input
                id="end_date"
                className="form-control"
                type="date"
                min={startDate}
                max={currentDate()}
                value={endDate}
                onChange={(event) => setEndDate(event.target.value)}
                disabled={startDate === ''}
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Select
                className="mt-3"
                options={outcomeMonths}
                value={month}
                onChange={(value) => setMonth(value)}
                placeholder="Filter by month..."
                isSearchable={true}
                isClearable={true}
              />
            </Col>
            <Col md={3}>
              <Select
                className="mt-3"
                options={years}
                value={year}
                onChange={(value) => setYear(value)}
                placeholder="Filter by year..."
                isSearchable={true}
                isClearable={true}
              />
            </Col>
            {role !== undefined && !isSpecifiedTownship(role) && (
              <Col sm={3}>
                <Select
                  className="mt-3"
                  options={townships}
                  value={selectedTownship}
                  onChange={(value) => setSelectedTownship(value)}
                  placeholder="Filter by township..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
            )}
          </Row>
        </CardHeader>
        <table id="table-to-xls">
          <table className={styles.styled_table}>
            <thead>
              <tr>
                <th colSpan={7} scope="col">
                  Status of smoking
                </th>
              </tr>
              <tr>
                <th scope="col">Month</th>
                <th scope="col"></th>
                <th scope="col">Current Smoker (S)</th>
                <th scope="col">Relapsed smoker ( R)</th>
                <th scope="col">Quitter (Q)</th>
                <th scope="col">Died (D)</th>
                <th scope="col">Lost to follow-up (L)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>0</td>
                <td>
                  <i
                    className={classnames(
                      'fa fa-question-circle',
                      styles.tooltip,
                    )}
                  >
                    <span className={classnames(styles.tooltiptext)}>
                      Count the number TB cases (who were registered in the same
                      quarter of previous year) that tick "Yes"in "Smoking
                      Yes/No" column of Month 0.
                    </span>
                  </i>
                </td>
                <td>{table_1_month_0_S}</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>2/3</td>
                <td>
                  <i
                    className={classnames(
                      'fa fa-question-circle',
                      styles.tooltip,
                    )}
                  >
                    <span className={classnames(styles.tooltiptext)}>
                      Count the number of TB cases (who were registered in the
                      same quarter of previous year) that tick "S,R,Q,D,L" in
                      "If Smoking "Yes" in previous visit,Status of smoking for
                      this follow up To skip for T/I patient" column in Month
                      2/3.
                    </span>
                  </i>
                </td>
                <td>{table_1_month_2_3_S}</td>
                <td>{table_1_month_2_3_R}</td>
                <td>{table_1_month_2_3_Q}</td>
                <td>{table_1_month_2_3_D}</td>
                <td>{table_1_month_2_3_L}</td>
              </tr>
              <tr>
                <td>5</td>
                <td>
                  <i
                    className={classnames(
                      'fa fa-question-circle',
                      styles.tooltip,
                    )}
                  >
                    <span className={classnames(styles.tooltiptext)}>
                      Count the number of TB cases (who were registered in the
                      same quarter of previous year)that tick "S,R,Q,D,L" in "If
                      Smoking "Yes" in previous visit,Status of smoking for this
                      follow up To skip for T/I patient" column in Month 5.
                    </span>
                  </i>
                </td>
                <td>{table_1_month_5_S}</td>
                <td>{table_1_month_5_R}</td>
                <td>{table_1_month_5_Q}</td>
                <td>{table_1_month_5_D}</td>
                <td>{table_1_month_5_L}</td>
              </tr>
              <tr>
                <td>End</td>
                <td>
                  <i
                    className={classnames(
                      'fa fa-question-circle',
                      styles.tooltip,
                    )}
                  >
                    <span className={classnames(styles.tooltiptext)}>
                      Count the number of TB cases (who were registered in the
                      same quarter of previous year) that tick "S,R,Q,D,L" in
                      "If Smoking "Yes" in previous visit,Status of smoking for
                      this follow up To skip for T/I patient" column in Month
                      End.
                    </span>
                  </i>
                </td>
                <td>{table_1_month_end_S}</td>
                <td>{table_1_month_end_R}</td>
                <td>{table_1_month_end_Q}</td>
                <td>{table_1_month_end_D}</td>
                <td>{table_1_month_end_L}</td>
              </tr>
            </tbody>
          </table>

          <table className={styles.styled_table}>
            <thead>
              <tr>
                <th colSpan={2} scope="col">
                  Status of exposure to smoking inside the home (for current
                  smokers at month 0)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td className="text-center">Yes(Y)</td>
              </tr>
              <tr>
                <td>0</td>
                <td className="text-center">{table_2_month_0}</td>
              </tr>
              <tr>
                <td>End</td>
                <td className="text-center">{table_2_month_end}</td>
              </tr>
            </tbody>
          </table>

          <table className={styles.styled_table}>
            <thead>
              <tr>
                <th scope="col">Outcome at the End of TB treatment</th>
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  % of current smokers at month 0 who were current smokers at
                  End [S(End)/S(0)x100]
                </td>
                <td>
                  <i
                    className={classnames(
                      'fa fa-question-circle',
                      styles.tooltip,
                    )}
                  >
                    <span className={classnames(styles.tooltiptext)}>
                      (Current Smoker (S) at Month End ÷ Current Smoker (S) at
                      Month 0)× 100
                    </span>
                  </i>
                </td>
                <td>{table_3_1}%</td>
              </tr>
              <tr>
                <td>
                  % of current smokers at month 0 who were relapsed smokers at
                  End [R(End)/S(0)x100]
                </td>
                <td>
                  <i
                    className={classnames(
                      'fa fa-question-circle',
                      styles.tooltip,
                    )}
                  >
                    <span className={classnames(styles.tooltiptext)}>
                      (Relapse Smoker(R) at Month End ÷ Current Smoker (S) at
                      Month 0)× 100
                    </span>
                  </i>
                </td>
                <td>{table_3_2}%</td>
              </tr>
              <tr>
                <td>
                  % of current smokers at month 0 who were quitters at End
                  [Q(End)/S(0)x100]
                </td>
                <td>
                  <i
                    className={classnames(
                      'fa fa-question-circle',
                      styles.tooltip,
                    )}
                  >
                    <span className={classnames(styles.tooltiptext)}>
                      (Quitter (Q) at Month End ÷ Current Smoker (S) at Month
                      0)× 100
                    </span>
                  </i>
                </td>
                <td>{table_3_3}%</td>
              </tr>
              <tr>
                <td>
                  % of current smokers at month 0 who had died or were lost to
                  follow up at End [D(End)+L(End)/S(0)x100]
                </td>
                <td>
                  <i
                    className={classnames(
                      'fa fa-question-circle',
                      styles.tooltip,
                    )}
                  >
                    <span className={classnames(styles.tooltiptext)}>
                      {
                        '{(Died (D) at Month End+ Lost to follow up (L))÷ Current Smoker (S) at Month 0}× 100'
                      }
                    </span>
                  </i>
                </td>
                <td>{table_3_4}%</td>
              </tr>
              <tr>
                <td>
                  % of current smokers exposed to smoking inside the home at
                  month 0 who were still exposed at End [Y(End)/Y(0)x100]
                </td>
                <td>
                  <i
                    className={classnames(
                      'fa fa-question-circle',
                      styles.tooltip,
                    )}
                  >
                    <span className={classnames(styles.tooltiptext)}>
                      ( Indicator 20 ÷ Indicator 19)×100
                    </span>
                  </i>
                </td>
                <td>{table_3_5}%</td>
              </tr>
            </tbody>
          </table>
        </table>
      </Card>
    </Container>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  township: store.township,
  report: store.report,
})

export default connect(mapStateToProps, {
  getTownships,
  getOutcomeReport,
})(OutcomeReport)
