import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Form } from 'reactstrap'
import CustomInput from 'components/Inputs/CustomInput'
import CustomModal from 'components/Modal/CustomModal'
import { connect } from 'react-redux'
import { createDistrict } from 'store/actions'

const DistrictCreate = ({ isOpen, toggle, status, createDistrict }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()

  const [loading, setLoading] = useState(false)

  const onSubmit = async (data) => {
    setLoading(true)
    await createDistrict(data)
    setLoading(false)
  }

  useEffect(() => {
    if (status.success) {
      reset()
    }
  }, [status.success, reset])

  return (
    <CustomModal
      isOpen={isOpen}
      title="Create District"
      onClick={() => {
        toggle(false)
        reset()
      }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <CustomInput
            id="name"
            label="District Name"
            register={{
              ...register('name', {
                required: 'District Name is required!',
              }),
            }}
            placeholder="Enter district name"
            errors={errors}
          />
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? 'Loading' : 'Save'}
          </Button>
        </div>
      </Form>
    </CustomModal>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
})

export default connect(mapStateToProps, { createDistrict })(DistrictCreate)
