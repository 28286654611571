import {
  CREATE_TOWNSHIP,
  DELETE_TOWNSHIP,
  SET_TOWNSHIP,
  SHOW_TOWNSHIPS,
  UPDATE_TOWNSHIP,
} from '../type'

const initialState = {
  townships: [],
  township: {},
  total: 0,
}

const township = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_TOWNSHIPS:
      return {
        ...state,
        townships: action.payload.data,
        total: action.payload.total,
      }
    case CREATE_TOWNSHIP:
      return {
        ...state,
        townships: [action.payload, ...state.townships],
        total: state.total + 1,
      }
    case DELETE_TOWNSHIP:
      return {
        ...state,
        townships: state.townships.filter(
          (township) => township.id !== action.payload,
        ),
        total: state.total - 1,
      }
    case SET_TOWNSHIP:
      return {
        ...state,
        township: action.payload,
      }
    case UPDATE_TOWNSHIP:
      if (JSON.stringify(state.township) !== JSON.stringify(action.payload)) {
        let clone = [...state.townships]
        let index = clone.findIndex(
          (township) => township.id === action.payload.id,
        )
        clone[index] = action.payload
        return {
          ...state,
          townships: clone,
          township: action.payload,
        }
      } else {
        return state
      }
    default:
      return state
  }
}

export default township
