import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Form } from 'reactstrap'
import CustomInput from 'components/Inputs/CustomInput'
import CustomSelect from 'components/Inputs/CustomSelect'
import CustomModal from 'components/Modal/CustomModal'
import { connect } from 'react-redux'
import { createTownship } from 'store/actions'

const TownshipCreate = ({
  isOpen,
  toggle,
  status,
  region,
  district,
  createTownship,
}) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()

  const [loading, setLoading] = useState(false)
  const [selectedRegion, setSelectedRegion] = useState(null)
  const [selectedDistrict, setSelectedDistrict] = useState(null)

  const regions = region.regions.map((element) => ({
    value: element.id,
    label: element.name,
  }))

  const districts = district.districts.map((element) => ({
    value: element.id,
    label: element.name,
  }))

  const onSubmit = async (data) => {
    setLoading(true)
    await createTownship({
      name: data.name,
      region_id: data.region.value,
      district_id: data.district.value,
      abbreviation: data.abbreviation,
    })
    setLoading(false)
  }

  const formReset = useCallback(() => {
    setSelectedRegion(null)
    setSelectedDistrict(null)
    reset()
  }, [reset, setSelectedRegion, setSelectedDistrict])

  useEffect(() => {
    if (status.success) {
      formReset()
    }

    return () => formReset()
  }, [status.success, formReset])

  return (
    <CustomModal
      isOpen={isOpen}
      title="Create Township"
      onClick={() => {
        toggle(false)
        formReset()
      }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <CustomSelect
            id="region"
            label="Select Region"
            rules={{ required: 'Region is required!' }}
            control={control}
            options={regions}
            value={selectedRegion}
            setData={setSelectedRegion}
            placeholder="Select region"
          />
          <CustomSelect
            id="district"
            label="Select District"
            rules={{ required: 'District is required!' }}
            control={control}
            options={districts}
            value={selectedDistrict}
            setData={setSelectedDistrict}
            placeholder="Select district"
          />
          <CustomInput
            id="name"
            label="Township Name"
            register={{
              ...register('name', {
                required: 'Township Name is required!',
              }),
            }}
            placeholder="Enter township name"
            errors={errors}
          />
          <CustomInput
            id="abbreviation"
            label="Township Acronym"
            register={{
              ...register('abbreviation', {
                required: 'Township Acronym is required!',
              }),
            }}
            placeholder="Enter township acronym"
            errors={errors}
          />
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? 'Loading' : 'Save'}
          </Button>
        </div>
      </Form>
    </CustomModal>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  region: store.region,
  district: store.district,
})

export default connect(mapStateToProps, {
  createTownship,
})(TownshipCreate)
