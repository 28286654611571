import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import CustomInput from "components/Inputs/CustomInput";
import CustomSelect from "components/Inputs/CustomSelect";
import CustomModal from "components/Modal/CustomModal";
import { connect } from "react-redux";
import { createAccount } from "store/actions";
import { roles } from "variables/options";

const AccountCreate = ({ isOpen, toggle, status, township, createAccount }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState(null);
  const [selectedTownship, setSelectedTownship] = useState(null);

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const onSubmit = async (data) => {
    setLoading(true);
    await createAccount({
      name: data.name,
      email: data.email,
      role: data.role.value,
      township_id: selectedTownship?.value,
    });
    setLoading(false);
  };

  const formReset = useCallback(() => {
    setRole(null);
    setSelectedTownship(null);
    reset();
  }, [reset, setRole, setSelectedTownship]);

  useEffect(() => {
    if (status.success) {
      formReset();
    }
  }, [status.success, formReset]);

  return (
    <CustomModal
      isOpen={isOpen}
      title="Create Account"
      onClick={() => {
        toggle(false);
        formReset();
      }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <CustomInput
            id="name"
            label="Name"
            register={{
              ...register("name", {
                required: "Name is required!",
              }),
            }}
            placeholder="Enter name"
            errors={errors}
          />
          <CustomInput
            id="email"
            label="Email"
            register={{
              ...register("email", {
                required: "Email is required!",
              }),
            }}
            placeholder="Enter email"
            type="email"
            errors={errors}
          />
          <CustomSelect
            id="role"
            label="Select Role"
            rules={{ required: "Role is required!" }}
            control={control}
            options={roles}
            value={role}
            setData={(data) => {
              setSelectedTownship(null);
              setRole(data);
            }}
            placeholder="Select role"
          />
          {(role?.value === "Field Officer" ||
            role?.value === "Nurse Counsellor") && (
            <CustomSelect
              id="township"
              label="Select Township"
              rules={{
                required:
                  (role?.value === "Field Officer" ||
                  role?.value === "Nurse Counsellor")
                    ? "Township is required!"
                    : false,
              }}
              control={control}
              options={townships}
              value={selectedTownship}
              setData={setSelectedTownship}
              placeholder="Select township"
            />
          )}
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? "Loading" : "Save"}
          </Button>
        </div>
      </Form>
    </CustomModal>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  township: store.township,
});

export default connect(mapStateToProps, { createAccount })(AccountCreate);
