import React, { useEffect, useState } from "react";
import CustomTable from "components/Table/Table";
import { Button, Card, CardHeader, Container, Row, Col } from "reactstrap";
import RegionCreate from "./RegionCreate";
import RegionUpdate from "./RegionUpdate";
import { connect } from "react-redux";
import { getRegions, getRegion, deleteRegion } from "store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import CustomAlert from "components/Alert/CustomAlert";
import BackBtn from "utils/backBtn";
import { basicDataMiddleware, CREATE, UPDATE, DELETE } from "utils/middleware";

const RegionList = ({
  status,
  auth,
  region,
  getRegions,
  getRegion,
  deleteRegion,
}) => {
  const { role } = auth.user;
  const [alert, setAlert] = useState(null);

  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);

  useEffect(() => {
    getRegions();
  }, [getRegions]);

  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={() => {
          deleteRegion(id);
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
      />
    );
  };

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">State/Regions</h3>
              </Col>
              {basicDataMiddleware(role, CREATE) && (
                <Col className="text-right" xs="6">
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => setIsCreateOpen(true)}
                  >
                    New
                  </Button>
                </Col>
              )}
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Name</th>
                {basicDataMiddleware(role, UPDATE) && (
                  <th scope="col">Action</th>
                )}
              </tr>
            }
            body={region.regions.map((region, index) => (
              <tr key={region.id}>
                <td>{index + 1}</td>
                <td>{region.name}</td>
                {basicDataMiddleware(role, UPDATE) &&
                  basicDataMiddleware(role, DELETE) && (
                    <td>
                      <Button
                        size="sm"
                        color="primary"
                        onClick={async () => {
                          await getRegion(region.id);
                          setIsUpdateOpen(true);
                        }}
                      >
                        Update
                      </Button>
                      <Button
                        size="sm"
                        color="danger"
                        onClick={() => handleDelete(region.id)}
                      >
                        Delete
                      </Button>
                    </td>
                  )}
              </tr>
            ))}
          />
        </Card>
      </Container>

      <RegionUpdate isOpen={isUpdateOpen} toggle={setIsUpdateOpen} />
      <RegionCreate isOpen={isCreateOpen} toggle={setIsCreateOpen} />
    </>
  );
};

const mapStateToProps = (store) => ({
  auth: store.auth,
  status: store.status,
  region: store.region,
});

export default connect(mapStateToProps, {
  getRegions,
  getRegion,
  deleteRegion,
})(RegionList);
