import { call } from "services/api";

export const patientExport = async (query) => {
  const response = await call(
    "get",
    `patients-export?${new URLSearchParams(query).toString()}`
  );
  const result = response.data.map((data, index) => ({
    UniqueKey: data.id,
    No: index + 1,
    NurseCounsellor: data.nurse_counsellor?.name,
    TBCodeRegistration: data.TB_code_registration,
    TownshipName: data.township.abbreviation,
    SerialNumber: data.serial_number,
    RegDate: data.date_of_registration,
    TreatmentStartDate: data.treatment_start_date,
    Yr: data.year,
    TownshipTBCode: data.union_temporary_TB_code,
    UniqueID: data.unique_id,
    NameOfPatient: data.name,
    SexOfPatient: data.sex,
    EthnicGroupOfPatient: data.ethnic_group,
    OtherEthnicGroupOfPatient: data.other_ethnic_group,
    AgeOfPatient: data.age,
    Phone: data.phone,
    Address: data.address,
    TypeOfTB: data.type_of_TB_depending_on_bac_status,
    RegimenType: data.regimen_type,
    StatusOfSmoking:
      data.month_0_transfer_in === "No" && data.month_0_smoking === null
        ? "Transfer IN"
        : data.month_0_smoking,
    StartTimeOfSmoking: data.month_0_time_after_waking_to_first_cigarette,
    CigaratteTypes: data.month_0_ciga_types,
    HomeSmokingStatus:
      data.month_0_status_of_exposure_to_smoking_inside_the_home,
    BriefAdvice: data.month_0_brief_advice,
    CessationSupport: data.month_0_cessation_support,
    NRTService: data.month_0_patient_request_for_NRT_service,
    FollowUpDate1: data.month_2_3_date,
    FUOption1: data.month_2_3_option,
    SmokingStatus_TI1:
      data.month_2_3_transfer_in === "No" &&
      data.month_2_3_transfer_in_patient_this_month_smoking === null
        ? "Transfer IN"
        : data.month_2_3_transfer_in_patient_this_month_smoking,
    StartTimeOfSmoking1: data.month_2_3_time_after_waking_to_first_cigarette,
    CigaratteTypes1: data.month_2_3_ciga_types,
    SmokingStatus_FU1:
      data.month_2_3_status_of_smoking_to_skip_transfer_in_patient,
    HomeSmokingStatus1:
      data.month_2_3_status_of_exposure_to_smoking_inside_the_home,
    BriefAdvice1: data.month_2_3_brief_advice,
    CessationSupport1: data.month_2_3_cessation_support,
    NRTService1: data.month_2_3_patient_request_for_NRT_service,
    FollowUpDate2: data.month_5_date,
    FUOption2: data.month_5_option,
    SmokingStatus_TI2:
      data.month_5_transfer_in === "No" &&
      data.month_5_transfer_in_patient_this_month_smoking === null
        ? "Transfer IN"
        : data.month_5_transfer_in_patient_this_month_smoking,
    CigaratteTypes2: data.month_5_ciga_types,

    StartTimeOfSmoking2: data.month_5_time_after_waking_to_first_cigarette,
    SmokingStatus_FU2:
      data.month_5_status_of_smoking_to_skip_transfer_in_patient,
    HomeSmokingStatus2:
      data.month_5_status_of_exposure_to_smoking_inside_the_home,
    BriefAdvice2: data.month_5_brief_advice,
    CessationSupport2: data.month_5_cessation_support,
    NRTService2: data.month_5_patient_request_for_NRT_service,
    FollowUpDate3: data.month_end_date,
    FUOption3: data.month_end_option,
    SmokingStatus_TI3:
      data.month_end_transfer_in === "No" &&
      data.month_end_transfer_in_patient_this_month_smoking === null
        ? "Transfer IN"
        : data.month_end_transfer_in_patient_this_month_smoking,
    StartTimeOfSmoking3: data.month_end_time_after_waking_to_first_cigarette,
    CigaratteTypes3: data.month_end_ciga_types,
    SmokingStatus_FU3:
      data.month_end_status_of_smoking_to_skip_transfer_in_patient,
    HomeSmokingStatus3:
      data.month_end_status_of_exposure_to_smoking_inside_the_home,
    BriefAdvice3: data.month_end_brief_advice,
    CessationSupport3: data.month_end_cessation_support,
    NRTService3: data.month_end_patient_request_for_NRT_service,
    FinalOutcome: data.final_outcome,
    SupportReceived: data.support_received,
    SupportReceivedDate: data.support_received_date,
    TreatmentOutcome: data.treatment_outcome,
    TreatmentOutcomeDate: data.treatment_outcome_date,
    Remark: data.remark,
  }));

  return { response, result };
};
