import CustomSelect from "components/Inputs/CustomSelect";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
} from "reactstrap";
import { timeAfterWakingToFirstCigarette } from "variables/options";
import { yesOrNo } from "variables/options";
import { patientMiddleware, UPDATE } from "utils/middleware";
import { connect } from "react-redux";
import { cigaTypeOptions } from "variables/options";

const Month0 = ({ auth }) => {
  const { role } = auth.user;
  const { control, handleSubmit } = useForm();

  const [loading, setLoading] = useState(false);
  const [selectedSmoking, setSelectedSmoking] = useState(null);
  const [selectedCigaTypes, setSelectedCigaTypes] = useState(null);
  const [
    selectedTimeAfterWakingToFirstCigarette,
    setSelectedTimeAfterWakingToFirstCigarette,
  ] = useState(null);
  const [
    selectedStatusOfExposureToSmokingInsideTheHome,
    setSelectedStatusOfExposureToSmokingInsideTheHome,
  ] = useState(null);
  const [selectedBriefAdvice, setSelectedBriefAdvice] = useState(null);
  const [selectedCessationSupport, setSelectedCessationSupport] =
    useState(null);
  const [
    selectedPatientRequestForNRTService,
    setSelectedPatientRequestForNRTService,
  ] = useState(null);

  const onSubmit = async () => {
    setLoading(true);

    setLoading(false);
  };

  return (
    <Container className="mt-3" fluid>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardHeader className="border-0">
            <h3>Month 0</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={6}>
                <CustomSelect
                  id="month_0_smoking"
                  label="Smoking"
                  control={control}
                  rules={{
                    required: "Smoking is required!",
                  }}
                  options={yesOrNo}
                  value={selectedSmoking}
                  setData={setSelectedSmoking}
                  placeholder="Smoking"
                  isRequired={true}
                />
              </Col>
              <Col md={6}>
                <CustomSelect
                  id="month_0_ciga_types"
                  label="Month 0 smoking cigaratte types"
                  control={control}
                  rules={{
                    required:
                      selectedSmoking?.value === "Yes"
                        ? "Month 0 smoking cigaratte types is required!"
                        : false,
                  }}
                  options={cigaTypeOptions}
                  value={selectedCigaTypes}
                  setData={setSelectedCigaTypes}
                  placeholder="Month 0 smoking cigaratte types"
                  isRequired={selectedSmoking?.value === "Yes"}
                  isDisabled={
                    selectedSmoking === null || selectedSmoking?.value !== "Yes"
                  }
                  isClearable={true}
                  isMulti={true}
                />
              </Col>
              <Col md={6}>
                <CustomSelect
                  id="month_0_time_after_waking_to_first_cigarette"
                  label="Time after waking to first cigarette"
                  control={control}
                  rules={{
                    required:
                      selectedSmoking?.value === "Yes"
                        ? "Time after waking to first cigarette is required!"
                        : false,
                  }}
                  options={timeAfterWakingToFirstCigarette}
                  value={selectedTimeAfterWakingToFirstCigarette}
                  setData={setSelectedTimeAfterWakingToFirstCigarette}
                  placeholder="Time after waking to first cigarette"
                  isRequired={selectedSmoking?.value === "Yes"}
                  isDisabled={
                    selectedSmoking === null || selectedSmoking?.value !== "Yes"
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <CustomSelect
                  id="month_0_status_of_exposure_to_smoking_inside_the_home"
                  label="Status of exposure to smoking inside the home"
                  control={control}
                  rules={{
                    required:
                      "Status of exposure to smoking inside the home is required!",
                  }}
                  options={yesOrNo}
                  value={selectedStatusOfExposureToSmokingInsideTheHome}
                  setData={setSelectedStatusOfExposureToSmokingInsideTheHome}
                  placeholder="Status of exposure to smoking inside the home"
                  isRequired={true}
                />
              </Col>
              <Col md={6}>
                <CustomSelect
                  id="month_0_brief_advice"
                  label="Brief Advice"
                  control={control}
                  rules={{
                    required: "Brief Advice is required!",
                  }}
                  options={yesOrNo}
                  value={selectedBriefAdvice}
                  setData={setSelectedBriefAdvice}
                  placeholder="Brief Advice"
                  isRequired={true}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <CustomSelect
                  id="month_0_cessation_support"
                  label="Cessation Support"
                  control={control}
                  rules={{
                    required: "Cessation Support is required!",
                  }}
                  options={yesOrNo}
                  value={selectedCessationSupport}
                  setData={setSelectedCessationSupport}
                  placeholder="Cessation Support"
                  isRequired={true}
                />
              </Col>
              <Col md={6}>
                <CustomSelect
                  id="month_0_patient_request_for_NRT_service"
                  label="Patient request for NRT service"
                  control={control}
                  rules={{
                    required: "Patient request for NRT service is required!",
                  }}
                  options={yesOrNo}
                  value={selectedPatientRequestForNRTService}
                  setData={setSelectedPatientRequestForNRTService}
                  placeholder="Patient request for NRT service"
                  isRequired={true}
                />
              </Col>
            </Row>
          </CardBody>
          {patientMiddleware(role, UPDATE) && (
            <CardFooter className="text-right">
              <Button
                color="primary"
                size="sm"
                disabled={loading}
                type="submit"
              >
                {loading ? "Loading" : "Update"}
              </Button>
            </CardFooter>
          )}
        </Card>
      </Form>
    </Container>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
});

export default connect(mapStateToProps, {})(Month0);
