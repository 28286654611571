import React from 'react'
import { useHistory } from 'react-router'
import {
 CardFooter,

  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap'
import queryString from 'query-string'

const CustomPagination = ({ pageCount }) => {
  const router = useHistory()
  const count = Math.ceil(pageCount)
  const query = queryString.parse(router.location.search)
  const pageChange = (i) => {
    query.page = i
    router.push(`${router.location.pathname}?${queryString.stringify(query)}`)
  }

  return (
    <CardFooter className="py-4 d-flex justify-content-end">
      <Pagination
        className="justify-content-end"
        style={{ maxWidth: '400px', overflowX: 'scroll' }}
      >
        {query.page > 1 && (
          <PaginationItem>
            <PaginationLink
              onClick={() => pageChange(parseInt(query.page) - 1)}
            >
              <i className="fas fa-angle-left" />
              <span className="sr-only">Previous</span>
            </PaginationLink>
          </PaginationItem>
        )}
        {Array(count)
          .fill(1)
          .map((_, index) => (
            <PaginationItem
              key={index}
              className={
                index + 1 === parseInt(query.page || 1) ? 'active' : ''
              }
            >
              <PaginationLink onClick={() => pageChange(index + 1)}>
                {index + 1}
              </PaginationLink>
            </PaginationItem>
          ))}
        {query.page < pageCount && (
          <PaginationItem>
            <PaginationLink
              onClick={() => pageChange(parseInt(query.page) + 1)}
            >
              <i className="fas fa-angle-right" />
              <span className="sr-only">Next</span>
            </PaginationLink>
          </PaginationItem>
        )}
      </Pagination>
    </CardFooter>
  )
}

export default CustomPagination
