import {
  CREATE_PATIENT,
  DELETE_PATIENT,
  SET_PATIENT,
  SHOW_PATIENTS,
  UPDATE_PATIENT,
  SERIAL_NUMBER,
  SHOW_FOLLOW_UP_PATIENTS,
} from '../type'

const initialState = {
  patients: [],
  followUpPatients: [],
  patient: {},
  male: 0,
  female: 0,
  total: 0,
  serialNumber: null,
}

const patient = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_PATIENTS:
      return {
        ...state,
        patients: action.payload.data,
        male: action.payload.male,
        female: action.payload.female,
        total: action.payload.total,
      }
    case SHOW_FOLLOW_UP_PATIENTS:
      return {
        ...state,
        followUpPatients: action.payload.data,
        male: action.payload.male,
        female: action.payload.female,
        total: action.payload.total,
      }
    case CREATE_PATIENT:
      return {
        ...state,
        patients: [action.payload, ...state.patients],
        male: action.payload.sex === 'Male' ? state.male + 1 : state.male,
        female:
          action.payload.sex === 'Female' ? state.female + 1 : state.female,
        total: state.total + 1,
      }
    case DELETE_PATIENT:
      let clone = [...state.patients]
      let index = clone.findIndex((patient) => patient.id === action.payload)
      let patient = clone[index]
      return {
        ...state,
        patients: clone.filter((patient) => patient.id !== action.payload),
        male: patient.sex === 'Male' ? state.male - 1 : state.male,
        female: patient.sex === 'Female' ? state.female - 1 : state.female,
        total: state.total - 1,
      }
    case SET_PATIENT:
      return {
        ...state,
        patient: action.payload,
      }
    case UPDATE_PATIENT:
      if (JSON.stringify(state.patient) !== JSON.stringify(action.payload)) {
        let clone = [...state.patients]
        let index = clone.findIndex(
          (patient) => patient.id === action.payload.id,
        )
        clone[index] = action.payload
        return {
          ...state,
          patients: clone,
          patient: action.payload,
        }
      } else {
        return state
      }
    case SERIAL_NUMBER:
      return {
        ...state,
        serialNumber: action.payload.data,
      }
    default:
      return state
  }
}

export default patient
