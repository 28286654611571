import {
  CREATE_DISTRICT,
  DELETE_DISTRICT,
  SET_DISTRICT,
  SHOW_DISTRICTS,
  UPDATE_DISTRICT,
} from '../type'

const initialState = {
  districts: [],
  district: {},
}

const district = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_DISTRICTS:
      return {
        ...state,
        districts: action.payload,
      }
    case CREATE_DISTRICT:
      return {
        ...state,
        districts: [action.payload, ...state.districts],
      }
    case DELETE_DISTRICT:
      return {
        ...state,
        districts: state.districts.filter(
          (district) => district.id !== action.payload,
        ),
      }
    case SET_DISTRICT:
      return {
        ...state,
        district: action.payload,
      }
    case UPDATE_DISTRICT:
      if (JSON.stringify(state.district) !== JSON.stringify(action.payload)) {
        let clone = [...state.districts]
        let index = clone.findIndex(
          (district) => district.id === action.payload.id,
        )
        clone[index] = action.payload
        return {
          ...state,
          districts: clone,
          district: action.payload,
        }
      } else {
        return state
      }
    default:
      return state
  }
}

export default district
