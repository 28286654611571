import { call, setAccessToken } from '../../services/api'
import { serverErrorMessage } from '../../utils/messages'
import {
  CREATE_NURSE_COUNSELLOR,
  DELETE_NURSE_COUNSELLOR,
  SET_NURSE_COUNSELLOR,
  SET_LOADING,
  SET_SUCCESS,
  SHOW_NURSE_COUNSELLORS,
  UPDATE_NURSE_COUNSELLOR,
} from '../type'
import { NotificationManager } from 'react-notifications'

export const getNurseCounsellors = (query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      const result = await call(
        'get',
        `nurse-counsellors?${new URLSearchParams(query).toString()}`,
      )

      dispatch({
        type: SHOW_NURSE_COUNSELLORS,
        payload: result,
      })
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const getNurseCounsellor = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    dispatch({ type: SET_SUCCESS, payload: false })
    try {
      const response = await call('get', `nurse-counsellors/${id}`)
      const result = response.data

      dispatch({
        type: SET_NURSE_COUNSELLOR,
        payload: result,
      })
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const createNurseCounsellor = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_SUCCESS, payload: false })
    try {
      const response = await call('post', 'nurse-counsellors', data)
      const result = response.data

      dispatch({
        type: CREATE_NURSE_COUNSELLOR,
        payload: result,
      })
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      })
      NotificationManager.success(
        'Nurse Counsellor has been created successfully!',
      )
    } catch (error) {
      const { status, data } = error.response
      if (status === 400) {
        const obj = data.data
        NotificationManager.error(obj[Object.keys(obj)[0]])
      } else if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      })
    }
  }
}

export const updateNurseCounsellor = (id, data) => {
  return async (dispatch) => {
    try {
      const response = await call(
        'post',
        `nurse-counsellors/${id}?_method=PUT`,
        data,
      )
      const result = response.data

      dispatch({
        type: UPDATE_NURSE_COUNSELLOR,
        payload: result,
      })
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      })
      NotificationManager.success(
        'Nurse Counsellor has been updated successfully!',
      )
    } catch (error) {
      const { status, data } = error.response
      if (status === 400) {
        const obj = data.data
        NotificationManager.error(obj[Object.keys(obj)[0]])
      } else if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      })
    }
  }
}

export const recoverNurseCounsellor = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      await call('get', `nurse-counsellors-recover/${id}`)

      dispatch({ type: DELETE_NURSE_COUNSELLOR, payload: id })
      NotificationManager.success(
        'Nurse Counsellor has been recovered successfully!',
      )
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const temDeleteNurseCounsellor = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      await call('get', `nurse-counsellors-delete/${id}`)

      dispatch({ type: DELETE_NURSE_COUNSELLOR, payload: id })
      NotificationManager.success(
        'Nurse Counsellor has been deleted successfully!',
      )
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const deleteNurseCounsellor = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      await call('delete', `nurse-counsellors/${id}`)

      dispatch({ type: DELETE_NURSE_COUNSELLOR, payload: id })
      NotificationManager.success(
        'Nurse Counsellor has been deleted successfully!',
      )
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}
