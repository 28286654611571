import Login from 'views/pages/auth/Login'
import Dashboard from 'views/pages/dashboard'
import AccountList from 'views/pages/dashboard/account/AccountList'
import DistrictList from 'views/pages/dashboard/basicData/district/DistrictList'
import RegionList from 'views/pages/dashboard/basicData/region/RegionList'
import TownshipList from 'views/pages/dashboard/basicData/township/TownshipList'
import ChangePassword from 'views/pages/auth/ChangePassword'
import PatientList from 'views/pages/dashboard/patient/PatientList'
import TransferredPatientList from 'views/pages/dashboard/transferredPatient/transferredPatientList'
import notTransferredPatientList from 'views/pages/dashboard/notTransferredPatient/notTransferredPatientList'
import TransferredPatientCreate from 'views/pages/dashboard/transferredPatient/transferredPatientCreate'
import NotTransferredPatientCreate from 'views/pages/dashboard/notTransferredPatient/notTransferredPatientCreate'
import TransferredPatientDetail from 'views/pages/dashboard/transferredPatient/transferredPatientDetail'
import NotTransferredPatientDetail from 'views/pages/dashboard/notTransferredPatient/notTransferredPatientDetail'
import TrashPatientList from 'views/pages/dashboard/trash/TrashPatientList'
import NurseCounsellorList from 'views/pages/dashboard/nurseCounsellor/NurseCounsellorList'
import TrashNurseCounsellorList from 'views/pages/dashboard/trash/TrashNurseCounsellorList'
import VolunteerHandoutList from 'views/pages/handout/VolunteerHandoutList'
import MonthlyReport from 'views/pages/dashboard/report/MonthlyReport'
import OutcomeReport from 'views/pages/dashboard/report/OutcomeReport'
import FormAndRegisterList from 'views/pages/formAndRegister/FormAndRegisterList'
import FollowUpPatientList from 'views/pages/dashboard/followUpPatient/FollowUpPatientList'

const routes = [
  {
    path: '/login',
    name: 'Login',
    icon: 'ni ni-archive-2 text-green',
    component: Login,
    layout: '/auth',
    invisible: true,
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    icon: 'ni ni-archive-2 text-green',
    component: ChangePassword,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'ni ni-tv-2 text-primary',
    component: Dashboard,
    layout: '/admin',
  },
  {
    path: '/account-list',
    name: 'Accounts',
    icon: 'ni ni-single-02 text-primary',
    component: AccountList,
    layout: '/admin',
  },
  {
    collapse: true,
    name: 'Basic Data',
    icon: 'ni ni-chart-bar-32 text-primary',
    state: 'basicDataCollapse',
    views: [
      {
        path: '/region-list',
        name: 'State/Regions',
        miniName: 'RL',
        component: RegionList,
        layout: '/admin',
      },
      {
        path: '/district-list',
        name: 'Districts',
        miniName: 'DL',
        component: DistrictList,
        layout: '/admin',
      },
      {
        path: '/township-list',
        name: 'Townships',
        miniName: 'TL',
        component: TownshipList,
        layout: '/admin',
      },
    ],
  },
  {
    path: '/nurse-counsellor-list',
    name: 'Nurse Counsellors',
    icon: 'fas fa-user-friends text-primary',
    component: NurseCounsellorList,
    layout: '/admin',
  },
  {
    collapse: true,
    name: 'Patients',
    icon: 'ni ni-collection text-primary',
    state: 'patientDataCollapse',
    views: [
      {
        path: '/patient-list',
        name: 'All Patients',
        miniName: 'PL',
        component: PatientList,
        layout: '/admin',
      },
      {
        path: '/not-transferred-patient-list',
        name: 'TB Patients',
        miniName: 'TPL',
        component: notTransferredPatientList,
        layout: '/admin',
      },
      {
        path: '/transferred-patient-list',
        name: 'TB Patients(TI)',
        miniName: 'TPL',
        component: TransferredPatientList,
        layout: '/admin',
      },
      {
        path: '/follow-up-patient-list',
        name: 'Follow Up Patients',
        miniName: 'FUP',
        component: FollowUpPatientList,
        layout: '/admin',
      },
    ],
  },
  {
    path: '/transferred-patient-create',
    name: 'TransferredPatientCreate',
    component: TransferredPatientCreate,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/not-transferred-patient-create',
    name: 'NotTransferredPatientCreate',
    component: NotTransferredPatientCreate,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/transferred-patient-detail/:id',
    name: 'TransferedPatientDetail',
    icon: 'ni ni-archive-2 text-purple',
    component: TransferredPatientDetail,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/not-transferred-patient-detail/:id',
    name: 'NotTransferedPatientDetail',
    icon: 'ni ni-archive-2 text-purple',
    component: NotTransferredPatientDetail,
    layout: '/admin',
    invisible: true,
  },
  {
    collapse: true,
    name: 'Trash',
    icon: 'ni ni-app text-primary',
    state: 'trashCollapse',
    views: [
      {
        path: '/patient-trash-list',
        name: 'All Patients',
        miniName: 'AP',
        component: TrashPatientList,
        layout: '/admin',
      },
      {
        path: '/nurse-counsellor-trash-list',
        name: 'Nurse Counsellors',
        miniName: 'NC',
        component: TrashNurseCounsellorList,
        layout: '/admin',
      },
    ],
  },
  {
    path: '/handout',
    name: 'Handout',
    icon: 'ni ni-collection text-primary',
    component: VolunteerHandoutList,
    layout: '/admin',
  },
  {
    path: '/form-and-register',
    name: 'Form And Register',
    icon: 'ni ni-single-copy-04 text-primary',
    component: FormAndRegisterList,
    layout: '/admin',
  },
  {
    collapse: true,
    name: 'Report',
    icon: 'ni ni-book-bookmark text-primary',
    state: 'reportCollapse',
    views: [
      {
        path: '/monthly-report',
        name: 'Monthly Report',
        miniName: 'MR',
        component: MonthlyReport,
        layout: '/admin',
      },
      {
        path: '/outcome-report',
        name: 'Outcome Report',
        miniName: 'OR',
        component: OutcomeReport,
        layout: '/admin',
      },
    ],
  },
]

export default routes
