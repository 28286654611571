import React, { useEffect, useState } from 'react'
import { Button, Card, CardHeader, Container, Row, Col } from 'reactstrap'
import CustomTable from 'components/Table/Table'
import VolunteerHandoutCreate from './VolunteerHandoutCreate'
import { connect } from 'react-redux'
import { getVolunteerHandouts, getVolunteerHandout } from 'store/actions'
import FullScreenLoading from 'components/FullScreenLoading/FullScreenLoading'
import VolunteerHandoutUpdate from './VolunteerHandoutUpdate'
import { handoutMiddleware, CREATE, UPDATE } from 'utils/middleware'
import BackBtn from 'utils/backBtn'

const VolunteerHandoutList = ({
  status,
  auth,
  volunteerHandout,
  getVolunteerHandouts,
  getVolunteerHandout,
}) => {
  const { role } = auth.user

  const [isCreateOpen, setIsCreateOpen] = useState(false)
  const [isUpdateOpen, setIsUpdateOpen] = useState(false)

  useEffect(() => {
    getVolunteerHandouts()
  }, [getVolunteerHandouts])

  if (status.loading) {
    return <FullScreenLoading />
  }

  return (
    <>
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Volunteer Handout</h3>
              </Col>
              {handoutMiddleware(role, CREATE) &&
                volunteerHandout.volunteerHandouts.length === 0 && (
                  <Col className="text-right" xs="6">
                    <Button
                      size="sm"
                      color="success"
                      onClick={() => setIsCreateOpen(true)}
                    >
                      New
                    </Button>
                  </Col>
                )}
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th scope="col">File Link</th>
                {handoutMiddleware(role, UPDATE) && <th scope="col">Action</th>}
              </tr>
            }
            body={volunteerHandout.volunteerHandouts.map((volunteerHandout) => (
              <tr key={volunteerHandout.id}>
                <td>
                  <a href={volunteerHandout.file} target="_black">
                    Click Link To View Handout File
                  </a>
                </td>
                {handoutMiddleware(role, UPDATE) && (
                  <td>
                    <Button
                      size="sm"
                      color="primary"
                      onClick={async () => {
                        await getVolunteerHandout(volunteerHandout.id)
                        setIsUpdateOpen(true)
                      }}
                    >
                      Update
                    </Button>
                  </td>
                )}
              </tr>
            ))}
          />
        </Card>
      </Container>

      <VolunteerHandoutUpdate isOpen={isUpdateOpen} toggle={setIsUpdateOpen} />
      <VolunteerHandoutCreate isOpen={isCreateOpen} toggle={setIsCreateOpen} />
    </>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  volunteerHandout: store.volunteerHandout,
})

export default connect(mapStateToProps, {
  getVolunteerHandouts,
  getVolunteerHandout,
})(VolunteerHandoutList)
