import React, { useEffect, useState } from "react";
import Select from "react-select";
import CustomTable from "components/Table/Table";
import { Button, Card, CardHeader, Container, Row, Col } from "reactstrap";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import { getTownships, getFollowUpPatients } from "store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import BackBtn from "utils/backBtn";
import queryString from "query-string";
import { NotificationManager } from "react-notifications";
import { patientMiddleware } from "utils/middleware";
import { EXPORT } from "utils/middleware";
import { isSpecifiedTownship } from "utils/middleware";
import { excelExport } from "utils/excelExport";
import { call } from "services/api";
import CustomPagination from "components/Pagination/Pagination";
import { paginationLimit } from "variables/limits";
import { currentDate } from "utils/currentDate";
import { handleDate } from "utils/dateArray";
import { handleCheckCurrentMonth } from "utils/dateArray";

const FollowUpPatientList = ({
  status,
  auth,
  township,
  getTownships,
  patient,
  getFollowUpPatients,
}) => {
  const router = useHistory();
  const { role } = auth.user;

  const [exportLoading, setExportLoading] = useState(false);

  const [selectedTownship, setSelectedTownship] = useState(null);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [monthYear, setMonthYear] = useState("");

  const [name, setName] = useState("");
  const [code, setCode] = useState("");

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    if (!("page" in query)) {
      query.page = 1;
    }
    getFollowUpPatients(query);
  }, [router.location.search, getFollowUpPatients]);

  useEffect(() => {
    if (role !== undefined && !isSpecifiedTownship(role)) {
      getTownships();
    }
  }, [getTownships, role]);

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const handleFilter = () => {
    const query = queryString.parse(router.location.search);
    query.page = 1;

    if (selectedTownship) {
      query.township_id = selectedTownship.value;
    } else {
      delete query["township_id"];
    }

    if (startDate !== "") {
      query.start_date = startDate;
    } else {
      delete query["start_date"];
    }

    if (endDate !== "") {
      query.end_date = endDate;
    } else {
      delete query["end_date"];
    }

    if (monthYear !== "") {
      query.month_year = monthYear;
    } else {
      delete query["month_year"];
    }

    if (name !== "") {
      query.name = name;
    } else {
      delete query["name"];
    }

    if (code !== "") {
      query.union_temporary_TB_code = code;
    } else {
      delete query["union_temporary_TB_code"];
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
  };

  if (status.loading) {
    return <FullScreenLoading />;
  }

  const handleExport = async () => {
    setExportLoading(true);
    try {
      const query = queryString.parse(router.location.search);
      const response = await call(
        "get",
        `follow-up-patients-export?${new URLSearchParams(query).toString()}`
      );
      const result = response.data.map((data, index) => ({
        UniqueKey: data.patient.id,
        No: index + 1,
        FollowUpDate: handleDate(new Date(data.follow_up_date)),
        NurseCounsellor: data.patient.nurse_counsellor?.name,
        TBCodeRegistration: data.patient.TB_code_registration,
        TownshipName: data.patient.township.abbreviation,
        SerialNumber: data.patient.serial_number,
        RegDate: data.patient.date_of_registration,
        TreatmentStartDate: data.patient.treatment_start_date,
        Yr: data.patient.year,
        TownshipTBCode: data.patient.union_temporary_TB_code,
        UniqueID: data.patient.unique_id,
        NameOfPatient: data.patient.name,
        SexOfPatient: data.patient.sex,
        AgeOfPatient: data.patient.age,
        TypeOfTB: data.patient.type_of_TB_depending_on_bac_status,
        RegimenType: data.patient.regimen_type,
        StatusOfSmoking:
          data.patient.month_0_transfer_in === "No" &&
          data.patient.month_0_smoking === null
            ? "Transfer IN"
            : data.patient.month_0_smoking,
        StartTimeOfSmoking:
          data.patient.month_0_time_after_waking_to_first_cigarette,
        HomeSmokingStatus:
          data.patient.month_0_status_of_exposure_to_smoking_inside_the_home,
        BriefAdvice: data.patient.month_0_brief_advice,
        CessationSupport: data.patient.month_0_cessation_support,
        NRTService: data.patient.month_0_patient_request_for_NRT_service,
        FollowUpDate1: data.patient.month_2_3_date,
        FUOption1: data.patient.month_2_3_option,
        SmokingStatus_TI1:
          data.patient.month_2_3_transfer_in === "No" &&
          data.patient.month_2_3_transfer_in_patient_this_month_smoking === null
            ? "Transfer IN"
            : data.patient.month_2_3_transfer_in_patient_this_month_smoking,
        StartTimeOfSmoking1:
          data.patient.month_2_3_time_after_waking_to_first_cigarette,
        SmokingStatus_FU1:
          data.patient.month_2_3_status_of_smoking_to_skip_transfer_in_patient,
        HomeSmokingStatus1:
          data.patient.month_2_3_status_of_exposure_to_smoking_inside_the_home,
        BriefAdvice1: data.patient.month_2_3_brief_advice,
        CessationSupport1: data.patient.month_2_3_cessation_support,
        NRTService1: data.patient.month_2_3_patient_request_for_NRT_service,
        FollowUpDate2: data.patient.month_5_date,
        FUOption2: data.patient.month_5_option,
        SmokingStatus_TI2:
          data.patient.month_5_transfer_in === "No" &&
          data.patient.month_5_transfer_in_patient_this_month_smoking === null
            ? "Transfer IN"
            : data.patient.month_5_transfer_in_patient_this_month_smoking,
        StartTimeOfSmoking2:
          data.patient.month_5_time_after_waking_to_first_cigarette,
        SmokingStatus_FU2:
          data.patient.month_5_status_of_smoking_to_skip_transfer_in_patient,
        HomeSmokingStatus2:
          data.patient.month_5_status_of_exposure_to_smoking_inside_the_home,
        BriefAdvice2: data.patient.month_5_brief_advice,
        CessationSupport2: data.patient.month_5_cessation_support,
        NRTService2: data.patient.month_5_patient_request_for_NRT_service,
        FollowUpDate3: data.patient.month_end_date,
        FUOption3: data.patient.month_end_option,
        SmokingStatus_TI3:
          data.patient.month_end_transfer_in === "No" &&
          data.patient.month_end_transfer_in_patient_this_month_smoking === null
            ? "Transfer IN"
            : data.patient.month_end_transfer_in_patient_this_month_smoking,
        StartTimeOfSmoking3:
          data.patient.month_end_time_after_waking_to_first_cigarette,
        SmokingStatus_FU3:
          data.patient.month_end_status_of_smoking_to_skip_transfer_in_patient,
        HomeSmokingStatus3:
          data.patient.month_end_status_of_exposure_to_smoking_inside_the_home,
        BriefAdvice3: data.patient.month_end_brief_advice,
        CessationSupport3: data.patient.month_end_cessation_support,
        NRTService3: data.patient.month_end_patient_request_for_NRT_service,
        FinalOutcome: data.final_outcome,
        Remark: data.patient.remark,
      }));

      let fileName = "All-FollowUp-Patients";
      if (selectedTownship) {
        fileName += `-${selectedTownship.label}`;
      }

      if (response.status === "success") {
        excelExport(result, fileName);
      }
    } catch (_) {
      NotificationManager.error("Please try again!");
    }
    setExportLoading(false);
  };

  return (
    <>
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-3">Follow Up Patient List</h3>
              </Col>
              <Col className="text-right" xs="6">
                {patientMiddleware(role, EXPORT) && (
                  <Button
                    size="sm"
                    color="info"
                    onClick={handleExport}
                    disabled={exportLoading}
                  >
                    {exportLoading ? "Loading" : "Export"}
                  </Button>
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <label htmlFor="start_date">Start Date</label>
                <input
                  id="start_date"
                  className="form-control"
                  type="date"
                  max={currentDate()}
                  value={startDate}
                  onChange={(event) => setStartDate(event.target.value)}
                />
              </Col>
              <Col sm={4}>
                <label htmlFor="end_date">End Date</label>
                <input
                  id="end_date"
                  className="form-control"
                  type="date"
                  min={startDate}
                  max={currentDate()}
                  value={endDate}
                  onChange={(event) => setEndDate(event.target.value)}
                  disabled={startDate === ""}
                />
              </Col>
              <Col sm={3}>
                <label htmlFor="month_year">Follow Up Month</label>
                <input
                  id="month_year"
                  className="form-control"
                  type="month"
                  value={monthYear}
                  onChange={(event) => setMonthYear(event.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={2}>
                <input
                  className="form-control mt-3"
                  type="text"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  placeholder="Search name..."
                />
              </Col>
              <Col sm={2}>
                <input
                  className="form-control mt-3"
                  type="text"
                  value={code}
                  onChange={(event) => setCode(event.target.value)}
                  placeholder="Search code..."
                />
              </Col>
              <Col sm={4}>
                {!isSpecifiedTownship(role) && (
                  <Select
                    className="mt-3"
                    options={townships}
                    value={selectedTownship}
                    onChange={(value) => setSelectedTownship(value)}
                    placeholder="Filter by township..."
                    isSearchable={true}
                    isClearable={true}
                  />
                )}
              </Col>
              <Col sm={4}>
                <Button
                  size="sm"
                  color="success"
                  onClick={handleFilter}
                  className="mt-3"
                >
                  <i className="fa fa-search" />
                </Button>
                <Button
                  size="sm"
                  style={{ backgroundColor: "#0C5393", color: "#fff" }}
                  className="mt-3"
                >
                  Male = {patient.male}
                </Button>
                <Button
                  size="sm"
                  style={{ backgroundColor: "#0C5393", color: "#fff" }}
                  className="mt-3"
                >
                  Female = {patient.female}
                </Button>
                <Button
                  size="sm"
                  style={{ backgroundColor: "#0C5393", color: "#fff" }}
                  className="mt-3"
                >
                  Total = {patient.male + patient.female}
                </Button>
              </Col>
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th className="fixed-cell left" scope="col">
                  ID
                </th>
                <th className="fixed-cell left" scope="col">
                  Unique ID
                </th>
                <th scope="col">Action</th>
                <th className="fixed-cell left" scope="col">
                  Name
                </th>
                <th scope="col">Follow Up Date</th>
                <th scope="col">Union Temporary TB Code</th>
                <th scope="col">Sex</th>
                <th scope="col">Age</th>
                <th scope="col">Phone</th>
                <th scope="col">Township</th>
              </tr>
            }
            body={patient.followUpPatients?.map((p, index) => (
              <tr key={index}>
                <td
                  style={
                    p.transfer_in === "Yes"
                      ? { backgroundColor: "#ff0000" }
                      : { backgroundColor: "#00ff00" }
                  }
                >
                  {index + 1}
                </td>
                <td>{p.patient.unique_id}</td>
                <td>
                  <Button
                    size="sm"
                    color="success"
                    onClick={() =>
                      p.patient.transfer_in === "Yes"
                        ? router.push(
                            `/admin/transferred-patient-detail/${p.patient.id}`
                          )
                        : router.push(
                            `/admin/not-transferred-patient-detail/${p.patient.id}`
                          )
                    }
                  >
                    Detail
                  </Button>
                </td>
                <td>{p.patient.name}</td>
                <td
                  style={
                    handleCheckCurrentMonth(new Date(p.follow_up_date)) === 0
                      ? { backgroundColor: "yellow" }
                      : handleCheckCurrentMonth(new Date(p.follow_up_date)) ===
                        -1
                      ? { backgroundColor: "red", color: "#fff" }
                      : {}
                  }
                >{`${handleDate(new Date(p.follow_up_date))}(${
                  p.follow_up_month
                })${p.patient.treatment_start_date ? "(TSD)" : "(RGD)"}`}</td>
                <td>{p.patient.union_temporary_TB_code}</td>
                <td>{p.patient.sex}</td>
                <td>{p.patient.age}</td>
                <td>{p.patient.phone}</td>
                <td>{p.patient.township?.name}</td>
              </tr>
            ))}
          />
          {patient.total > paginationLimit && (
            <CustomPagination pageCount={patient.total / paginationLimit} />
          )}
        </Card>
      </Container>
    </>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  township: store.township,
  patient: store.patient,
});

export default connect(mapStateToProps, {
  getTownships,
  getFollowUpPatients,
})(FollowUpPatientList);
