import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Col, Row, Container } from "reactstrap";
import CustomDateInput from "components/Inputs/CustomDateInput";
import CustomSelect from "components/Inputs/CustomSelect";
import { connect } from "react-redux";
import { updatePatient } from "../../../../../store/actions";
import { yesOrNo } from "variables/options";

const Supported = ({ patient, updatePatient }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [selectedSupportReceived, setSelectedSupportReceived] = useState(null);

  useEffect(() => {
    const { support_received, support_received_date } = patient.patient;
    setValue(
      "support_received",
      support_received
        ? { value: support_received, label: support_received }
        : null
    );
    setSelectedSupportReceived(
      support_received
        ? { value: support_received, label: support_received }
        : null
    );
    setValue("support_received_date", support_received_date);
  }, [patient.patient, setValue]);

  const onSubmit = async (values) => {
    setLoading(true);
    await updatePatient(
      patient.patient.id,
      {
        support_received: values.support_received
          ? selectedSupportReceived?.value
          : null,
        support_received_date: selectedSupportReceived?.value === 'Yes'
          ? values.support_received_date
          : null,
      },
      "support-received",
      "Support Received"
    );
    setLoading(false);
  };
  const onClear = async (values) => {
    setLoading(true);
    await updatePatient(
      patient.patient.id,
      {
        support_received: null,
        support_received_date: null,
      },
      "support-received",
      "Support Received"
    );
    setLoading(false);
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={4}>
            <CustomSelect
              id="support_received"
              label="Support Received"
              rules={{
                required: "Support Received is required!",
              }}
              control={control}
              options={yesOrNo}
              value={selectedSupportReceived}
              setData={setSelectedSupportReceived}
              placeholder="Support Received"
            />
          </Col>
          {selectedSupportReceived?.value === "Yes" && (
            <Col md={4} className="mb-3">
              <CustomDateInput
                id="support_received_date"
                label="Support Received Date"
                register={{
                  ...register("support_received_date", {
                    required: "Support Received date is required",
                  }),
                }}
                errors={errors}
                min="1950-01-01"
                max="2100-12-31"
              />
            </Col>
          )}
        </Row>
        <div className=" modal-footer  d-flex align-items-end col-12">
          <Button color="primary" type="submit" disabled={loading} size="sm">
            {loading ? "Loading" : "Save"}
          </Button>
          <Button color="danger" disabled={loading} onClick={onClear} size="sm">
            {loading ? "Loading" : "Clear"}
          </Button>
        </div>
      </Form>
    </Container>
  );
};

const mapStateToProps = (store) => ({
  patient: store.patient,
});

export default connect(mapStateToProps, { updatePatient })(Supported);
