import React, { useEffect, useState } from "react";
import Select from "react-select";
import CustomTable from "components/Table/Table";
import { Button, Card, CardHeader, Container, Row, Col } from "reactstrap";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import { getTownships, getPatients } from "store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import BackBtn from "utils/backBtn";
import queryString from "query-string";
import { NotificationManager } from "react-notifications";
import { patientMiddleware } from "utils/middleware";
import { CREATE } from "utils/middleware";
import { EXPORT } from "utils/middleware";
import { isSpecifiedTownship } from "utils/middleware";
import { excelExport } from "utils/excelExport";
import CustomPagination from "components/Pagination/Pagination";
import { paginationLimit } from "variables/limits";
import { currentDate } from "utils/currentDate";
import {
  handleCheckingStatus,
  PENDING,
  APPROVED,
} from "utils/handleCheckingStatus";
import { patientExport } from "utils/patientExport";
import { checkingstatusOptions } from "variables/options";

const TransferredPatientList = ({
  status,
  auth,
  township,
  getTownships,
  patient,
  getPatients,
}) => {
  const router = useHistory();
  const { role } = auth.user;

  const [exportLoading, setExportLoading] = useState(false);

  const [selectedTownship, setSelectedTownship] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState({
    value: APPROVED,
    label: "APPROVED",
  });

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [name, setName] = useState("");
  const [code, setCode] = useState("");

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    if (!("page" in query)) {
      query.page = 1;
    }
    query.TB_patient_TI = true;
    getPatients(query);
  }, [router.location.search, getPatients]);

  useEffect(() => {
    if (role !== undefined && !isSpecifiedTownship(role)) {
      getTownships();
    }
  }, [getTownships, role]);

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const handleFilter = () => {
    const query = queryString.parse(router.location.search);
    query.page = 1;
    query.TB_patient_TI = true;

    if (selectedTownship) {
      query.township_id = selectedTownship.value;
    } else {
      delete query["township_id"];
    }

    if (selectedStatus) {
      query.checking_status = selectedStatus.value;
    } else {
      delete query["checking_status"];
    }

    if (startDate !== "") {
      query.start_date = startDate;
    } else {
      delete query["start_date"];
    }

    if (endDate !== "") {
      query.end_date = endDate;
    } else {
      delete query["end_date"];
    }

    if (name !== "") {
      query.name = name;
    } else {
      delete query["name"];
    }

    if (code !== "") {
      query.union_temporary_TB_code = code;
    } else {
      delete query["union_temporary_TB_code"];
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
  };

  if (status.loading) {
    return <FullScreenLoading />;
  }

  const handleExport = async () => {
    setExportLoading(true);
    try {
      const query = queryString.parse(router.location.search);
      query.TB_patient_TI = true;
      const { response, result } = await patientExport(query);

      let fileName = "TB-Patients(TI)";
      if (selectedTownship) {
        fileName += `-${selectedTownship.label}`;
      }

      if (response.status === "success") {
        excelExport(result, fileName);
      }
    } catch (_) {
      NotificationManager.error("Please try again!");
    }
    setExportLoading(false);
  };

  return (
    <>
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          {/* <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-3">TB Patient(TI) List</h3>
              </Col>
              <Col className="text-right" xs="6">
                <Button
                  size="sm"
                  color="warning"
                  onClick={() => handleCheckingStatus(router, PENDING)}
                >
                  Pending
                </Button>
                <Button
                  size="sm"
                  color="success"
                  onClick={() => handleCheckingStatus(router, APPROVED)}
                >
                  Approved
                </Button>
                <Button
                  size="sm"
                  color="primary"
                  onClick={() => router.replace(router.location.pathname)}
                >
                  <i class="fas fa-redo"></i>
                </Button>
                {patientMiddleware(role, CREATE) && (
                  <Button
                    size="sm"
                    color="success"
                    onClick={() =>
                      router.push(`/admin/transferred-patient-create`)
                    }
                  >
                    New
                  </Button>
                )}
                {patientMiddleware(role, EXPORT) && (
                  <Button
                    size="sm"
                    color="info"
                    onClick={handleExport}
                    disabled={exportLoading}
                  >
                    {exportLoading ? "Loading" : "Export"}
                  </Button>
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <label htmlFor="start_date">Start Date</label>
                <input
                  id="start_date"
                  className="form-control"
                  type="date"
                  max={currentDate()}
value={startDate}
                  onChange={(event) => setStartDate(event.target.value)}
                />
              </Col>
              <Col sm={4}>
                <label htmlFor="end_date">End Date</label>
                <input
                  id="end_date"
                  className="form-control"
                  type="date"
                  min={startDate}
                  max={currentDate()}
value={endDate}
                  onChange={(event) => setEndDate(event.target.value)}
                  disabled={startDate === ""}
                />
              </Col>
              <Col sm={4}>
                <Button
                  size="sm"
                  color="success"
                  onClick={handleFilter}
                  className="mt-3"
                >
                  <i className="fa fa-search" />
                </Button>
                <Button
                  size="sm"
                  style={{ backgroundColor: "#0C5393", color: "#fff" }}
                  className="mt-3"
                >
                  Male = {patient.male}
                </Button>
                <Button
                  size="sm"
                  style={{ backgroundColor: "#0C5393", color: "#fff" }}
                  className="mt-3"
                >
                  Female = {patient.female}
                </Button>
                <Button
                  size="sm"
                  style={{ backgroundColor: "#0C5393", color: "#fff" }}
                  className="mt-3"
                >
                  Total = {patient.male + patient.female}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <input
                  className="form-control mt-3"
                  type="text"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  placeholder="Search name..."
                />
              </Col>
              <Col sm={4}>
                <input
                  className="form-control mt-3"
                  type="text"
                  value={code}
                  onChange={(event) => setCode(event.target.value)}
                  placeholder="Search code..."
                />
              </Col>
              <Col sm={4}>
                {!isSpecifiedTownship(role) && (
                  <Select
                    className="mt-3"
                    options={townships}
                    value={selectedTownship}
                    onChange={(value) => setSelectedTownship(value)}
                    placeholder="Filter by township..."
                    isSearchable={true}
                    isClearable={true}
                  />
                )}
              </Col>
            </Row>
          </CardHeader> */}
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-3">TB Patient(TI) List</h3>
              </Col>
              <Col className="text-right" xs="6">
                <Button
                  size="sm"
                  style={{ backgroundColor: "#0C5393", color: "#fff" }}
                >
                  Male = {patient.male}
                </Button>
                <Button
                  size="sm"
                  style={{ backgroundColor: "#0C5393", color: "#fff" }}
                >
                  Female = {patient.female}
                </Button>
                <Button
                  size="sm"
                  style={{ backgroundColor: "#0C5393", color: "#fff" }}
                >
                  Total = {patient.male + patient.female}
                </Button>

                <Button
                  size="sm"
                  color="danger"
                  onClick={() => router.replace(router.location.pathname)}
                >
                  <i class="fas fa-redo"></i>
                </Button>
                {patientMiddleware(role, CREATE) && (
                  <Button
                    size="sm"
                    color="success"
                    onClick={() =>
                      router.push(`/admin/transferred-patient-create`)
                    }
                  >
                    New
                  </Button>
                )}
                {patientMiddleware(role, EXPORT) && (
                  <Button
                    size="sm"
                    color="info"
                    onClick={handleExport}
                    disabled={exportLoading}
                  >
                    {exportLoading ? "Loading" : "Export"}
                  </Button>
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <label htmlFor="start_date">Start Date</label>
                <input
                  id="start_date"
                  className="form-control"
                  type="date"
                  max={currentDate()}
                  value={startDate}
                  onChange={(event) => setStartDate(event.target.value)}
                />
              </Col>
              <Col sm={3}>
                <label htmlFor="end_date">End Date</label>
                <input
                  id="end_date"
                  className="form-control"
                  type="date"
                  min={startDate}
                  max={currentDate()}
                  value={endDate}
                  onChange={(event) => setEndDate(event.target.value)}
                  disabled={startDate === ""}
                />
              </Col>
              <Col sm={3}>
                <Select
                  className="mt-4"
                  options={checkingstatusOptions}
                  value={selectedStatus}
                  onChange={(value) => setSelectedStatus(value)}
                  placeholder="Filter by status..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
              <Col sm={3}>
                <Button
                  size="sm"
                  color="success"
                  onClick={handleFilter}
                  className="mt-4"
                >
                  <i className="fa fa-search" />
                </Button>
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <input
                  className="form-control mt-3"
                  type="text"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  placeholder="Search name..."
                />
              </Col>
              <Col sm={4}>
                <input
                  className="form-control mt-3"
                  type="text"
                  value={code}
                  onChange={(event) => setCode(event.target.value)}
                  placeholder="Search code..."
                />
              </Col>
              <Col sm={4}>
                {!isSpecifiedTownship(role) && (
                  <Select
                    className="mt-3"
                    options={townships}
                    value={selectedTownship}
                    onChange={(value) => setSelectedTownship(value)}
                    placeholder="Filter by township..."
                    isSearchable={true}
                    isClearable={true}
                  />
                )}
              </Col>
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th className="fixed-cell left" scope="col">
                  ID
                </th>
                <th className="fixed-cell left" scope="col">
                  Unique ID
                </th>
                <th scope="col">Action</th>
                <th className="fixed-cell left" scope="col">
                  Name
                </th>
                <th scope="col">TB Code</th>
                <th scope="col">Sex</th>
                <th scope="col">Age</th>
                <th scope="col">Phone</th>
                <th scope="col">Township</th>
              </tr>
            }
            body={patient.patients?.map((p, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{p.unique_id}</td>
                <td>
                  <Button
                    size="sm"
                    color={
                      p.checking_status === PENDING ? "warning" : "success"
                    }
                    onClick={() =>
                      router.push(`/admin/transferred-patient-detail/${p.id}`)
                    }
                  >
                    Detail
                  </Button>
                </td>
                <td>{p.name}</td>
                <td>{p.union_temporary_TB_code}</td>
                <td>{p.sex}</td>
                <td>{p.age}</td>
                <td>{p.phone}</td>
                <td>{p.township?.name}</td>
              </tr>
            ))}
          />
          {patient.total > paginationLimit && (
            <CustomPagination pageCount={patient.total / paginationLimit} />
          )}
        </Card>
      </Container>
    </>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  township: store.township,
  patient: store.patient,
});

export default connect(mapStateToProps, {
  getTownships,
  getPatients,
})(TransferredPatientList);
