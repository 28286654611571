import queryString from "query-string";

export const PENDING = 'pending';
export const NEW_DATA_PENDING = 'new_sync_data_pending';
export const APPROVED = 'approved';

export const handleCheckingStatus = (router, status) => {
    const query = queryString.parse(router.location.search);
    query.page = 1;

    if(status === PENDING) {
        query.checking_status = PENDING;
    }
    if(status === APPROVED) {
        query.checking_status = APPROVED;
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
}