import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Form, Row, Col } from 'reactstrap'
import CustomInput from 'components/Inputs/CustomInput'
import CustomSelect from 'components/Inputs/CustomSelect'
import CustomTextArea from 'components/Inputs/CustomTextArea'
import CustomModal from 'components/Modal/CustomModal'
import { sex, yesOrNo, educations } from 'variables/options'
import CustomDateInput from 'components/Inputs/CustomDateInput'
import { connect } from 'react-redux'
import { createNurseCounsellor } from 'store/actions'
import { isSpecifiedTownship } from 'utils/middleware'
import { currentDate } from 'utils/currentDate'
import { occuptions } from 'variables/options'
import { phoneSupporteds } from 'variables/options'

const NurseCounsellorCreate = ({
  isOpen,
  toggle,
  status,
  auth,
  township,
  createNurseCounsellor,
}) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()

  const { role } = auth.user
  const [loading, setLoading] = useState(false)
  const [gender, setGender] = useState(null)
  const [education, setEducation] = useState(null)
  const [selectedTownship, setSelectedTownship] = useState(null)
  const [pastHistoryOfTB, setPastHistoryOfTB] = useState(null)
  const [
    previousVolunteerExperience,
    setPreviousVolunteerExperience,
  ] = useState(null)
  const [selectedOccuption, setSelectedOccuption] = useState(null)
  const [selectedPhoneSupported, setSelectedPhoneSupported] = useState(null)

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }))

  const onSubmit = async (data) => {
    setLoading(true)
    await createNurseCounsellor({
      name: data.name,
      gender: data.gender.value,
      DOB: data.DOB,
      address: data.address,
      phone: data.phone,
      email: data.email,
      village: data.village,
      ward: data.ward,
      education_status: data.education_status.value,
      township_id:
        role !== undefined && !isSpecifiedTownship(role)
          ? data.township?.value
          : auth.user?.township?.id,
      past_history_of_TB: data.past_history_of_TB.value,
      previous_volunteer_experience: data.previous_volunteer_experience.value,
      occuption: data.occuption.value,
      phone_supported: data.phone_supported.value,
      remark: data.remark,
    })
    setLoading(false)
  }

  const formReset = useCallback(() => {
    setGender(null)
    setEducation(null)
    setSelectedTownship(null)
    setPastHistoryOfTB(null)
    setPreviousVolunteerExperience(null)
    setSelectedOccuption(null)
    setSelectedPhoneSupported(null)
    reset()
  }, [
    reset,
    setGender,
    setEducation,
    setSelectedTownship,
    setPastHistoryOfTB,
    setPreviousVolunteerExperience,
    setSelectedOccuption,
    setSelectedPhoneSupported,
  ])

  useEffect(() => {
    if (status.success) {
      formReset()
    }

    return () => formReset()
  }, [status.success, formReset])

  return (
    <CustomModal
      isOpen={isOpen}
      title="Create Nurse Counsellor"
      onClick={() => {
        toggle(false)
        formReset()
      }}
      style={{ maxWidth: '60%' }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <Row>
            <Col md={6}>
              <CustomInput
                id="name"
                label="Name"
                register={{
                  ...register('name', {
                    required: 'Name is required!',
                  }),
                }}
                placeholder="Enter Name"
                errors={errors}
              />
            </Col>
            <Col md={6}>
              <CustomSelect
                id="gender"
                label="Select Gender"
                rules={{ required: 'Gender is required!' }}
                control={control}
                options={sex}
                value={gender}
                setData={setGender}
                placeholder="Select gender"
              />
            </Col>
            <Col md={6}>
              <CustomDateInput
                id="DOB"
                label="DOB"
                register={{
                  ...register('DOB', {
                    required: 'DOB is required!',
                  }),
                }}
                placeholder="Select DOB"
                errors={errors}
                max={currentDate()}
              />
            </Col>
            <Col md={6}>
              <CustomInput
                id="address"
                label="Address"
                register={{
                  ...register('address', {
                    required: 'Address is required!',
                  }),
                }}
                placeholder="Enter Address"
                errors={errors}
              />
            </Col>
            <Col md={6}>
              <CustomInput
                id="phone"
                label="Phone"
                register={{
                  ...register('phone', {
                    required: 'Phone is required!',
                  }),
                }}
                placeholder="Enter Phone"
                errors={errors}
              />
            </Col>
            <Col md={6}>
              <CustomInput
                id="email"
                label="Email"
                register={{
                  ...register('email', {
                    required: false,
                  }),
                }}
                placeholder="example@gmail.com"
                errors={errors}
                isRequired={false}
                type="email"
              />
            </Col>
            <Col md={6}>
              {role !== undefined && !isSpecifiedTownship(role) ? (
                <CustomSelect
                  id="township"
                  label="Select Township"
                  rules={{ required: 'Township is required!' }}
                  control={control}
                  options={townships}
                  value={selectedTownship}
                  setData={setSelectedTownship}
                  placeholder="Select township"
                />
              ) : (
                <p>{auth.user?.township?.name}</p>
              )}
            </Col>
            <Col md={6}>
              <CustomInput
                id="village"
                label="Village"
                register={{
                  ...register('village', {
                    required: false,
                  }),
                }}
                placeholder="Enter village"
                errors={errors}
                isRequired={false}
              />
            </Col>
            <Col md={6}>
              <CustomInput
                id="ward"
                label="Ward"
                register={{
                  ...register('ward', {
                    required: false,
                  }),
                }}
                placeholder="Enter ward"
                errors={errors}
                isRequired={false}
              />
            </Col>
            <Col md={6}>
              <CustomSelect
                id="education_status"
                label="Select Education Status"
                rules={{ required: 'Education status is required!' }}
                control={control}
                options={educations}
                value={education}
                setData={setEducation}
                placeholder="Select education status"
              />
            </Col>
            <Col md={6}>
              <CustomSelect
                id="past_history_of_TB"
                label="Select Past History of TB"
                rules={{ required: 'Past History of TB is required!' }}
                control={control}
                options={yesOrNo}
                value={pastHistoryOfTB}
                setData={setPastHistoryOfTB}
                placeholder="Select Past History of TB"
              />
            </Col>
            <Col md={6}>
              <CustomSelect
                id="previous_volunteer_experience"
                label="Select Previous Volunteer Experience"
                rules={{
                  required: 'Previous Volunteer Experience is required!',
                }}
                control={control}
                options={yesOrNo}
                value={previousVolunteerExperience}
                setData={setPreviousVolunteerExperience}
                placeholder="Select Previous Volunteer Experience"
              />
            </Col>
            <Col md={6}>
              <CustomSelect
                id="occuption"
                label="Select Occuption"
                rules={{
                  required: 'Occuption is required!',
                }}
                control={control}
                options={occuptions}
                value={selectedOccuption}
                setData={setSelectedOccuption}
                placeholder="Select Occuption"
              />
            </Col>
            <Col md={6}>
              <CustomSelect
                id="phone_supported"
                label="Select Phone Supported"
                rules={{
                  required: 'Phone Supported is required!',
                }}
                control={control}
                options={phoneSupporteds}
                value={selectedPhoneSupported}
                setData={setSelectedPhoneSupported}
                placeholder="Select Phone Supported"
              />
            </Col>
            <Col md={6}>
              <CustomTextArea
                id="remark"
                label="Remark"
                register={{
                  ...register('remark'),
                }}
                placeholder="Write remark..."
                errors={errors}
              />
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? 'Loading' : 'Save'}
          </Button>
        </div>
      </Form>
    </CustomModal>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  township: store.township,
})

export default connect(mapStateToProps, {
  createNurseCounsellor,
})(NurseCounsellorCreate)
