import React from 'react'

const Dashboard = () => {
  return <>
  <h1 className="text-center">TB Tobacco Dashboard</h1>
  <iframe title="TB_tobacco - Home Page" width="100%" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=f645fb4f-161a-43d0-8db2-2a9e4c3136a7&autoAuth=true&ctid=d544b77d-0d8d-4bfc-8db4-b17e1113bef4" frameborder="0" allowFullScreen="true"></iframe>
  </>
}

export default Dashboard
