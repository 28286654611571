import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { Container, Card, CardHeader, Row, Col, Button } from 'reactstrap'
import BackBtn from 'utils/backBtn'
import { years } from 'variables/options'
import { reportMonths } from 'variables/options'
import styles from './MonthlyReport.module.css'
import { connect } from 'react-redux'
import { getTownships, getMonthlyReport } from 'store/actions'
import FullScreenLoading from 'components/FullScreenLoading/FullScreenLoading'
import { isSpecifiedTownship } from 'utils/middleware'
import { useHistory } from 'react-router'
import queryString from 'query-string'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
import classnames from 'classnames'
import { currentDate } from 'utils/currentDate'

const MonthlyReport = ({
  status,
  auth,
  township,
  report,
  getTownships,
  getMonthlyReport,
}) => {
  const { role } = auth.user
  const router = useHistory()

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const [month, setMonth] = useState(null)
  const [year, setYear] = useState(null)
  const [selectedTownship, setSelectedTownship] = useState(null)

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }))

  useEffect(() => {
    if (role !== undefined && !isSpecifiedTownship(role)) {
      getTownships()
    }
  }, [getTownships, role])

  const handleFilter = () => {
    const query = queryString.parse(router.location.search)

    if (startDate !== '') {
      query.start_date = startDate
    } else {
      delete query['start_date']
    }

    if (endDate !== '') {
      query.end_date = endDate
    } else {
      delete query['end_date']
    }

    if (selectedTownship) {
      query.township_id = selectedTownship.value
    } else {
      delete query['township_id']
    }

    if (month) {
      query.month = month.value
    } else {
      delete query['month']
    }

    if (year) {
      query.year = year.value
    } else {
      delete query['year']
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`)
  }

  useEffect(() => {
    const query = queryString.parse(router.location.search)
    getMonthlyReport(query)
  }, [getMonthlyReport, router.location.search])

  if (status.loading) {
    return <FullScreenLoading />
  }

  let fileName = 'Monthly-Report'
  if (month) {
    fileName += `-${month.label}`
  }
  if (year) {
    fileName += `-${year.label}`
  }
  if (selectedTownship) {
    fileName += `-${selectedTownship.label}`
  }

  const { _A, _B, _C, _D, _E, _F, _G, _H } = report.monthlyReport

  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Card>
        <CardHeader className="border-0">
          <Row>
            <Col xs={6}>
              <h3 className="mb-0">Monthly Report</h3>
            </Col>
            <Col className="text-right" xs={6}>
              <Button size="sm" color="success" onClick={handleFilter}>
                <i className="fa fa-search" />
              </Button>
              <ReactHTMLTableToExcel
                className="btn btn-info btn-sm"
                table="table-to-xls"
                filename={fileName}
                sheet="Monthly-Report"
                buttonText="Export"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <label htmlFor="start_date">Start Date</label>
              <input
                id="start_date"
                className="form-control"
                type="date"
                max={currentDate()}
                value={startDate}
                onChange={(event) => setStartDate(event.target.value)}
              />
            </Col>
            <Col sm={4}>
              <label htmlFor="end_date">End Date</label>
              <input
                id="end_date"
                className="form-control"
                type="date"
                min={startDate}
                max={currentDate()}
                value={endDate}
                onChange={(event) => setEndDate(event.target.value)}
                disabled={startDate === ''}
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Select
                className="mt-3"
                options={reportMonths}
                value={month}
                onChange={(value) => setMonth(value)}
                placeholder="Filter by month..."
                isSearchable={true}
                isClearable={true}
              />
            </Col>
            <Col md={3}>
              <Select
                className="mt-3"
                options={years}
                value={year}
                onChange={(value) => setYear(value)}
                placeholder="Filter by year..."
                isSearchable={true}
                isClearable={true}
              />
            </Col>
            {role !== undefined && !isSpecifiedTownship(role) && (
              <Col sm={3}>
                <Select
                  className="mt-3"
                  options={townships}
                  value={selectedTownship}
                  onChange={(value) => setSelectedTownship(value)}
                  placeholder="Filter by township..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
            )}
          </Row>
        </CardHeader>

        <table id="table-to-xls" className={styles.styled_table}>
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col">Indicators</th>
              <th scope="col"></th>
              <th scope="col">Number of Patients</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>A</td>
              <td>Number of smear-positive TB cases</td>
              <td>
                <i
                  className={classnames(
                    'fa fa-question-circle',
                    styles.tooltip,
                  )}
                >
                  <span className={classnames(styles.tooltiptext)}>
                    Count the number of TB cases registered in the reporting
                    period that tick "BC" in "Type of TB depending on Bac
                    Status" column
                  </span>
                </i>
              </td>
              <td>{_A}</td>
            </tr>
            <tr>
              <td>B</td>
              <td>Number of current smoker</td>
              <td>
                <i
                  className={classnames(
                    'fa fa-question-circle',
                    styles.tooltip,
                  )}
                >
                  <span className={classnames(styles.tooltiptext)}>
                    Count the total number of TB Cases registered in the
                    reporting period that tick "Yes" in "Smoking Yes/No" column
                    of Month 0, and tick "Yes" in "Only need to fill for patient
                    who was Transferred In (T/I) this month Smoking Yes/No"
                    column of Month 2/3, Month 5 and Month End.
                  </span>
                </i>
              </td>
              <td>{_B}</td>
            </tr>
            <tr>
              <td>C</td>
              <td>Number of current smoker with high level addiction</td>
              <td>
                <i
                  className={classnames(
                    'fa fa-question-circle',
                    styles.tooltip,
                  )}
                >
                  <span className={classnames(styles.tooltiptext)}>
                    Count the number of TB Cases that tick "≤ 30 min" in "If
                    Yes, Time after waking to first cigarette" column among the
                    TB Cases counted in "Number of current smoker" Indicator
                    (Indicator B)
                  </span>
                </i>
              </td>
              <td>{_C}</td>
            </tr>
            <tr>
              <td>D</td>
              <td>
                Number of current smoker who are exposed to smoking inside their
                home
              </td>
              <td>
                <i
                  className={classnames(
                    'fa fa-question-circle',
                    styles.tooltip,
                  )}
                >
                  <span className={classnames(styles.tooltiptext)}>
                    Count the number of TB Cases that tick "Yes" in "Status of
                    exposure to smoking inside the home" column among the TB
                    Cases counted in "Number of current smoker"
                    Indicator(Indicator B)
                  </span>
                </i>
              </td>
              <td>{_D}</td>
            </tr>
            <tr>
              <td>E</td>
              <td>
                Number of non-smokers who are exposed to smoking inside their
                home
              </td>
              <td>
                <i
                  className={classnames(
                    'fa fa-question-circle',
                    styles.tooltip,
                  )}
                >
                  <span className={classnames(styles.tooltiptext)}>
                    Count the total number of TB Cases registered in the
                    reporting period that tick "No" in "Smoking Yes/No" column
                    and tick "Yes" in "Status of exposure to smoking inside the
                    home" column of Month 0, and tick "No" in "Only need to fill
                    for patient who was Transferred In (T/I) this month Smoking
                    Yes/No" column and and tick "Yes" in "Status of exposure to
                    smoking inside the home" column of Month 2/3, Month 5 and
                    Month End.
                  </span>
                </i>
              </td>
              <td>{_E}</td>
            </tr>
            <tr>
              <td>F</td>
              <td>
                Number of non-smokers who are not exposed to smoking inside
                their home
              </td>
              <td>
                <i
                  className={classnames(
                    'fa fa-question-circle',
                    styles.tooltip,
                  )}
                >
                  <span className={classnames(styles.tooltiptext)}>
                    Count the total number of TB Cases registered in the
                    reporting period that tick "No" in "Smoking Yes/No" column
                    and tick "No" in "Status of exposure to smoking inside the
                    home" column of Month 0, and tick "No" in "Only need to fill
                    for patient who was Transferred In (T/I) this month Smoking
                    Yes/No" column and and tick "No" in "Status of exposure to
                    smoking inside the home" column of Month 2/3, Month 5 and
                    Month End.
                  </span>
                </i>
              </td>
              <td>{_F}</td>
            </tr>
            <tr>
              <td>G</td>
              <td>
                % of smear-positive case who are current smokers (B/A x100)
              </td>
              <td>
                <i
                  className={classnames(
                    'fa fa-question-circle',
                    styles.tooltip,
                  )}
                >
                  <span className={classnames(styles.tooltiptext)}>
                    (Indicator B ÷ Indicator A)× 100
                  </span>
                </i>
              </td>
              <td>{_G}%</td>
            </tr>
            <tr>
              <td>H</td>
              <td>
                % of smear-positive case who are current smokers and exposed to
                smoking inside their home (D/Ax100)
              </td>
              <td>
                <i
                  className={classnames(
                    'fa fa-question-circle',
                    styles.tooltip,
                  )}
                >
                  <span className={classnames(styles.tooltiptext)}>
                    (Indicator D ÷ Indicator A)× 100
                  </span>
                </i>
              </td>
              <td>{_H}%</td>
            </tr>
          </tbody>
        </table>
      </Card>
    </Container>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  township: store.township,
  report: store.report,
})

export default connect(mapStateToProps, {
  getTownships,
  getMonthlyReport,
})(MonthlyReport)
