import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  Form,
CardFooter,
  Button,
} from "reactstrap";
import { connect } from "react-redux";
import { updatePatient } from "../../../../../store/actions";
import CustomSelect from "components/Inputs/CustomSelect";
import { finalOutcomes } from "variables/options";

const FinalOutcome = ({ patient, updatePatient }) => {
  const { control, handleSubmit, setValue } = useForm();
  const [loading, setLoading] = useState(false);
  const [selectedFinalOutcome, setSelectedFinalOutcome] = useState(null);

  useEffect(() => {
    const { final_outcome } = patient.patient;
    setValue(
      "final_outcome",
      final_outcome ? { value: final_outcome, label: final_outcome } : null
    );
    setSelectedFinalOutcome(
      final_outcome ? { value: final_outcome, label: final_outcome } : null
    );
  }, [patient.patient, setValue]);

  const onSubmit = async () => {
    setLoading(true);
    await updatePatient(
      patient.patient?.id,
      {
        final_outcome: selectedFinalOutcome?.value,
      },
      "final-outcome",
      "Final Outcome"
    );
    setLoading(false);
  };

  return (
    <Container className="mt-3" fluid>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardHeader className="border-0">
            <h3>TB Patient(TI) Final Outcome</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={6}>
                <CustomSelect
                  id="final_outcome"
                  label="Final Outcome"
                  rules={{
                    required: "Final Outcome is required!",
                  }}
                  control={control}
                  options={finalOutcomes}
                  value={selectedFinalOutcome}
                  setData={setSelectedFinalOutcome}
                  placeholder="Final Outcome"
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter className="text-right">
            {patient.patient.final_outcome !== "non-current-smoker" && (
              <Button
                color="primary"
                size="sm"
                disabled={loading}
                type="submit"
              >
                {loading ? "Loading" : "Update"}
              </Button>
            )}
          </CardFooter>
        </Card>
      </Form>
    </Container>
  );
};
const mapStateToProps = (store) => ({
  patient: store.patient,
});

export default connect(mapStateToProps, { updatePatient })(FinalOutcome);
