export const CREATE = "create";
export const READ = "read";
export const UPDATE = "update";
export const DELETE = "delete";
export const EXPORT = "export";

const DEVELOPER = "Developer";
const PROGRAMME_DIRECTOR = 'Programme Director'

const SENIOR_TECHNICAL_ADVISER_TB = "Senior Technical Adviser (TB)";
const DEPUTY_PROGRAMME_DIRECTOR = "Deputy Programme Director";
const PROGRAMME_MANAGER_TB = "Programme Manager_TB";
const PROGRAMME_MANAGER_AIS = "Programme Manager_AIS";

const HIS_AND_DIGITAL_LISTERACY_MANAGER = "MEAL and Digital Tool Manager";
const MEAL_AND_DIGITAL_TOOL_OFFICER = "MEAL And Digital Tool Officer";
const MEAL_ASSOCIATE = "MEAL Associate";
const PROJECT_OFFICER = "Project Officer";
const FILED_OFFICER = "Field Officer";
const CLINIC_INCHECK = "Clinic Incheck";
const AIS_CLINIC_NURSE = "AIS Clinic Nurse";
const DATA_ASSISTANT = 'Data Assistant'

export const isSpecifiedTownship = (role) => role === FILED_OFFICER;

export const accountMiddleware = (role, type) => {
  if (
    role === DEVELOPER ||
    role === HIS_AND_DIGITAL_LISTERACY_MANAGER ||
    role === MEAL_AND_DIGITAL_TOOL_OFFICER ||
role === MEAL_ASSOCIATE ||
    role === DATA_ASSISTANT  ) {
    return true;
  } 
  // else if (
  //   (role === SENIOR_TECHNICAL_ADVISER_TB ||
  //      role === DEPUTY_PROGRAMME_DIRECTOR ||
  //    role === PROGRAMME_DIRECTOR ||
  //     role === PROGRAMME_MANAGER_TB ||
  //     role === PROGRAMME_MANAGER_AIS) &&
  //   type === READ
  // ) {
  //   return true;
  // } 
  else {
    return false;
  }
};

export const basicDataMiddleware = (role, type) => {
  if (
    role === DEVELOPER ||
    role === HIS_AND_DIGITAL_LISTERACY_MANAGER ||
    role === MEAL_AND_DIGITAL_TOOL_OFFICER ||
role === MEAL_ASSOCIATE ||
    role === DATA_ASSISTANT  ) {
    return true;
  } else if (
    (role === SENIOR_TECHNICAL_ADVISER_TB ||
       role === DEPUTY_PROGRAMME_DIRECTOR ||
      role === PROGRAMME_DIRECTOR ||
      role === PROGRAMME_MANAGER_TB ||
      role === PROGRAMME_MANAGER_AIS) &&
    type === READ
  ) {
    return true;
  } else {
    return false;
  }
};
export const patientMiddleware = (role, type) => {
  if (
    role === DEVELOPER ||
    role === HIS_AND_DIGITAL_LISTERACY_MANAGER ||
    role === MEAL_AND_DIGITAL_TOOL_OFFICER ||
role === MEAL_ASSOCIATE ||
    role === DATA_ASSISTANT  ) {
    return true;
  } else if (
    (role === SENIOR_TECHNICAL_ADVISER_TB ||
       role === DEPUTY_PROGRAMME_DIRECTOR ||
      role === PROGRAMME_DIRECTOR ||
      role === PROGRAMME_MANAGER_TB ||
      role === PROGRAMME_MANAGER_AIS ||
      role === PROJECT_OFFICER ||
      role === CLINIC_INCHECK ||
      role === FILED_OFFICER ||
      role === AIS_CLINIC_NURSE) &&
    (type === READ || type === EXPORT)
  ) {
    return true;
  } else {
    return false;
  }
};

export const nurseCounsellorMiddleware = (role, type) => {
  if (
    role === DEVELOPER ||
    role === HIS_AND_DIGITAL_LISTERACY_MANAGER ||
    role === MEAL_AND_DIGITAL_TOOL_OFFICER ||
    role === MEAL_ASSOCIATE ||
    role === FILED_OFFICER ||
    role === AIS_CLINIC_NURSE
  ) {
    return true;
  } else if (
    (role === SENIOR_TECHNICAL_ADVISER_TB ||
       role === DEPUTY_PROGRAMME_DIRECTOR ||
      role === PROGRAMME_DIRECTOR ||
      role === PROGRAMME_MANAGER_TB ||
      role === PROGRAMME_MANAGER_AIS ||
      role === PROJECT_OFFICER ||
      role === CLINIC_INCHECK) &&
    (type === READ || type === EXPORT)
  ) {
    return true;
  } else {
    return false;
  }
};

export const trashMiddleware = (role) => {
  if (
    role === DEVELOPER ||
    role === HIS_AND_DIGITAL_LISTERACY_MANAGER ||
    role === MEAL_AND_DIGITAL_TOOL_OFFICER ||
role === MEAL_ASSOCIATE ||
    role === DATA_ASSISTANT  ) {
    return true;
  } else {
    return false;
  }
};
export const handoutMiddleware = (role, type) => {
  return true;
};
