import {
  CREATE_VOLUNTEER_HANDOUT,
  SET_VOLUNTEER_HANDOUT,
  SHOW_VOLUNTEER_HANDOUTS,
  UPDATE_VOLUNTEER_HANDOUT,
} from '../type'

const initialState = {
  volunteerHandouts: [],
  volunteerHandout: {},
}

const volunteerHandout = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_VOLUNTEER_HANDOUTS:
      return {
        ...state,
        volunteerHandouts: action.payload,
      }
    case CREATE_VOLUNTEER_HANDOUT:
      return {
        ...state,
        volunteerHandouts: [action.payload, ...state.volunteerHandouts],
      }
    case SET_VOLUNTEER_HANDOUT:
      return {
        ...state,
        volunteerHandout: action.payload,
      }
    case UPDATE_VOLUNTEER_HANDOUT:
      if (JSON.stringify(state.volunteerHandout) !== JSON.stringify(action.payload)) {
        let clone = [...state.volunteerHandouts]
        let index = clone.findIndex((volunteerHandout) => volunteerHandout.id === action.payload.id)
        clone[index] = action.payload
        return {
          ...state,
          volunteerHandouts: clone,
          volunteerHandout: action.payload,
        }
      } else {
        return state
      }
    default:
      return state
  }
}

export default volunteerHandout
