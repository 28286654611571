import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";

import { Provider } from "react-redux";
import decode from "jwt-decode";
import store from "./store";
import { setAccessToken } from "./services/api";
import { getToken } from "utils/cache";
import { autoLogin } from "store/actions";
import { SET_CURRENT_USER } from "./store/type";

// react library for routing
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import PrivateRoute from "routers/PrivateRoute";
import AuthRoute from "routers/AuthRoute";

const token = getToken();

if (token) {
  setAccessToken(token);
  store.dispatch(autoLogin());
  store.dispatch({
    type: SET_CURRENT_USER,
    payload: decode(token),
  });
}

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <PrivateRoute path="/admin" component={AdminLayout} />
          <AuthRoute path="/auth" component={AuthLayout} />
          <Redirect from="*" to="/auth" />
        </Switch>
      </BrowserRouter>
      <NotificationContainer />
    </Provider>
  );
}
export default App;
