import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
} from "reactstrap";
import BackBtn from "utils/backBtn";
import { currentDate } from "utils/currentDate";
import CustomDateInput from "components/Inputs/CustomDateInput";
import CustomSelect from "components/Inputs/CustomSelect";
import { sex, ethnicGroupOptions } from "variables/options";
import CustomNumberInput from "components/Inputs/CustomNumberInput";
import { typeOfTBDependingOnBACStatus } from "variables/options";
import { yesOrNo } from "variables/options";
import { connect } from "react-redux";
import {
  createPatient,
  getTownships,
  getSerialNumber,
  getNurseCounsellors,
} from "store/actions";
import { TBCodeRegistration } from "variables/options";
import { years } from "variables/options";
import { isSpecifiedTownship } from "utils/middleware";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { regimenTypes } from "variables/options";
import CustomConfirmAlert from "components/Alert/CustomConfirmAlert";
import CustomInput from "components/Inputs/CustomInput";

const NotTransferredPatientCreate = ({
  auth,
  createPatient,
  township,
  getTownships,
  status,
  getSerialNumber,
  patient,
  nurseCounsellor,
  getNurseCounsellors,
}) => {
  const { role } = auth.user;

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const [alert, setAlert] = useState(null);

  const [loading, setLoading] = useState(false);
  const [selectedNurseCounsellor, setSelectedNurseCounsellor] = useState(null);
  const [selectedTBCodeRegistration, setSelectedTBCodeRegistration] =
    useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedSex, setSelectedSex] = useState(null);
  const [selectedEthnicGroup, setSelectedEthnicGroup] = useState(null);

  const [
    selectedTypeOfTBDependingOnBACStatus,
    setSelectedTypeOfTBDependingOnBACStatus,
  ] = useState(null);
  const [selectedTransfer, setSelectedTransfer] = useState({
    value: "No",
    label: "No",
  });
  const [selectedTownship, setSelectedTownship] = useState(null);
  const [selectedRegimenType, setSelectedRegimenType] = useState(null);

  useEffect(() => {
    if (role !== undefined && !isSpecifiedTownship(role)) {
      getTownships();
    }
    getNurseCounsellors();
  }, [getTownships, getNurseCounsellors, role]);

  const nurseCounsellors = nurseCounsellor.nurseCounsellors.map((element) => ({
    value: element.id,
    label: `${element.name}(${element.township?.abbreviation})`,
  }));

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const onSubmit = async (data) => {
    setAlert(
      <CustomConfirmAlert
        onConfirm={async () => {
          setAlert(null);
          setLoading(true);
          await createPatient({
            nurse_counsellor_id: selectedNurseCounsellor?.value,
            TB_code_registration: selectedTBCodeRegistration.value,
            year: selectedYear.value,
            serial_number: data.serial_number,
            date_of_registration: data.date_of_registration,
            treatment_start_date: data.treatment_start_date,
            union_temporary_TB_code: data.union_temporary_TB_code,
            name: data.name,
            sex: selectedSex.value,
            ethnic_group: selectedEthnicGroup?.value,
            other_ethnic_group:
              selectedEthnicGroup?.value === "Others"
                ? data.other_ethnic_group
                : null,

            age: data.age,
            address: data.address,
            phone: data.phone,
            transfer_in: selectedTransfer.value,
            township_id: selectedTownship.value,
            type_of_TB_depending_on_bac_status:
              selectedTypeOfTBDependingOnBACStatus.value,
            regimen_type: selectedRegimenType.value,
          });
          setLoading(false);
        }}
        onCancel={() => setAlert(null)}
        title="Are you sure to save patient?"
        label={`Date of Registration is ${data.date_of_registration}.  Treatment Start Date is ${data.treatment_start_date}`}
      />
    );
  };

  const formReset = useCallback(() => {
    reset();
    setSelectedNurseCounsellor(null);
    setSelectedTBCodeRegistration(null);
    setSelectedYear(null);
    setSelectedTownship(null);
    setSelectedSex(null);
    setSelectedTypeOfTBDependingOnBACStatus(null);
    setSelectedRegimenType(null);
    setSelectedEthnicGroup(null);
  }, [
    reset,
    setSelectedNurseCounsellor,
    setSelectedTBCodeRegistration,
    setSelectedYear,
    setSelectedTownship,
    setSelectedSex,
    setSelectedTypeOfTBDependingOnBACStatus,
    setSelectedRegimenType,
    setSelectedEthnicGroup,
  ]);

  useEffect(() => {
    if (status.success) {
      formReset();
    }

    return () => formReset();
  }, [status.success, formReset]);

  useEffect(() => {
    setValue("serial_number", patient.serialNumber);
  }, [patient.serialNumber, setValue]);

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <CardHeader className="border-0">
              <h3>Create TB Patient</h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={4}>
                  <CustomSelect
                    id="year"
                    label="Year"
                    rules={{ required: "Year is required!" }}
                    control={control}
                    options={years}
                    value={selectedYear}
                    setData={(data) => {
                      setSelectedYear(data);
                      setSelectedTownship(null);
                      setValue("township", null);
                    }}
                    placeholder="Year"
                  />
                </Col>
                <Col md={4}>
                  {role !== undefined && !isSpecifiedTownship(role) ? (
                    <CustomSelect
                      id="township"
                      label="Township"
                      rules={{ required: "Township is required!" }}
                      control={control}
                      options={townships}
                      value={selectedTownship}
                      setData={(data) => {
                        setSelectedTownship(data);
                        getSerialNumber(data?.value, selectedYear?.value);
                      }}
                      placeholder="Township"
                      isDisabled={selectedYear === null}
                    />
                  ) : (
                    <p>{auth.user?.township?.name}</p>
                  )}
                </Col>
                <Col md={4}>
                  <CustomNumberInput
                    id="serial_number"
                    label="Serial Number"
                    register={{
                      ...register("serial_number", {
                        required: "Serial Number is required!",
                      }),
                    }}
                    placeholder="Enter Serial Number"
                    errors={errors}
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <CustomSelect
                    id="nurse_counsellor"
                    label="Nurse Counsellor"
                    control={control}
                    options={nurseCounsellors}
                    value={selectedNurseCounsellor}
                    setData={setSelectedNurseCounsellor}
                    placeholder="Nurse Counsellor"
                    isRequired={false}
                  />
                </Col>
                <Col md={4}>
                  <CustomSelect
                    id="TB_code_registration"
                    label="TB Code Registration"
                    rules={{ required: "TB Code Registration is required!" }}
                    control={control}
                    options={TBCodeRegistration}
                    value={selectedTBCodeRegistration}
                    setData={setSelectedTBCodeRegistration}
                    placeholder="TB Code Registration"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <CustomDateInput
                    id="date_of_registration"
                    label="Date Of Registration"
                    register={{
                      ...register("date_of_registration", {
                        required: "Date Of Registration is required!",
                      }),
                    }}
                    placeholder="Select Date Of Registration"
                    errors={errors}
                    isRequired={true}
                    max={currentDate()}
                  />
                </Col>
                <Col md={4}>
                  <CustomDateInput
                    id="treatment_start_date"
                    label="Treatment Start Date"
                    register={{
                      ...register("treatment_start_date", false),
                    }}
                    placeholder="Select Treatment Start Date"
                    errors={errors}
                    isRequired={false}
                    max={currentDate()}
                  />
                </Col>
                <Col md={4}>
                  <CustomInput
                    id="union_temporary_TB_code"
                    label="TB Code"
                    register={{
                      ...register("union_temporary_TB_code", {
                        required: true,
                      }),
                    }}
                    placeholder="Enter TB Code"
                    errors={errors}
                    isRequired={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <CustomInput
                    id="name"
                    label="Name"
                    register={{
                      ...register("name", {
                        required: "Name is required!",
                      }),
                    }}
                    placeholder="Enter Name"
                    errors={errors}
                  />
                </Col>
                <Col md={4}>
                  <CustomSelect
                    id="sex"
                    label="Sex"
                    rules={{ required: "Sex is required!" }}
                    control={control}
                    options={sex}
                    value={selectedSex}
                    setData={setSelectedSex}
                    placeholder="Sex"
                  />
                </Col>
                <Col md={4}>
                  <CustomSelect
                    id="ethnic_group"
                    label="Ethnic group"
                    rules={{ required: false }}
                    control={control}
                    options={ethnicGroupOptions}
                    value={selectedEthnicGroup}
                    setData={setSelectedEthnicGroup}
                    placeholder="Ethnic Group"
                    isRequired={false}
                  />
                </Col>
                {selectedEthnicGroup?.value === "Others" && (
                  <Col md={4}>
                    <CustomInput
                      id="other_ethnic_group"
                      label="Other ethnic group"
                      register={{
                        ...register("other_ethnic_group", {
                          required: "Ethnic Group is required",
                        }),
                      }}
                      placeholder="Enter Other ethnic group"
                      errors={errors}
                      isRequired={true}
                    />
                  </Col>
                )}
                <Col md={4}>
                  <CustomNumberInput
                    id="age"
                    label="Age"
                    register={{
                      ...register("age", {
                        required: "Age is required!",
                      }),
                    }}
                    placeholder="Enter age"
                    errors={errors}
                    max={120}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <CustomInput
                    id="address"
                    label="Address"
                    register={{
                      ...register("address", {
                        required: false,
                      }),
                    }}
                    placeholder="Enter Address"
                    errors={errors}
                    isRequired={false}
                  />
                </Col>
                <Col md={4}>
                  <CustomInput
                    id="phone"
                    label="Phone"
                    register={{
                      ...register("phone", {
                        required: "Phone is required!",
                      }),
                    }}
                    placeholder="Enter Phone"
                    errors={errors}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <CustomSelect
                    id="type_of_TB_depending_on_BAC_status"
                    label="Type of TB depending on Bac Status"
                    rules={{
                      required:
                        "Type of TB depending on Bac Status is required!",
                    }}
                    control={control}
                    options={typeOfTBDependingOnBACStatus}
                    value={selectedTypeOfTBDependingOnBACStatus}
                    setData={setSelectedTypeOfTBDependingOnBACStatus}
                    placeholder="Type of TB depending on Bac Status"
                  />
                </Col>
                <Col md={4}>
                  <CustomSelect
                    id="regimen_type"
                    label="Regimen Type"
                    rules={{
                      required: "Regimen Type is required!",
                    }}
                    control={control}
                    options={regimenTypes}
                    value={selectedRegimenType}
                    setData={setSelectedRegimenType}
                    placeholder="Regimen Type"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <CustomSelect
                    id="transfer_in"
                    label="Transfer In"
                    control={control}
                    options={yesOrNo}
                    value={selectedTransfer}
                    setData={setSelectedTransfer}
                    placeholder="Transfer"
                    isDisabled={true}
                  />
                </Col>
              </Row>
            </CardBody>
            <CardFooter className="text-right">
              <Button
                color="primary"
                size="sm"
                disabled={loading}
                type="submit"
              >
                {loading ? "Loading" : "Save"}
              </Button>
            </CardFooter>
          </Card>
        </Form>
      </Container>
    </>
  );
};
const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  patient: store.patient,
  township: store.township,
  nurseCounsellor: store.nurseCounsellor,
});

export default connect(mapStateToProps, {
  createPatient,
  getTownships,
  getSerialNumber,
  getNurseCounsellors,
})(NotTransferredPatientCreate);
