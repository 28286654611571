export const handleDate = (date) => {
  var dd = String(date.getDate()).padStart(2, '0')
  var mm = String(date.getMonth() + 1).padStart(2, '0') //January is 0!
  var yyyy = date.getFullYear()

  date = yyyy + '-' + mm + '-' + dd

  return date
}

export const handleDateArray = (startDate, endDate) => {
  let arr = [],
    dt = new Date(startDate)

  while (dt <= endDate) {
    arr.push(handleDate(new Date(dt)))
    dt.setDate(dt.getDate() + 1)
  }

  return arr
}

export const handleCheckCurrentMonth = (date1) => {
  // equal 0, greater 1, less -1
  let date1_month = date1.getMonth() + 1
  let date1_year = date1.getFullYear()

  let date2 = new Date()
  let date2_month = date2.getMonth() + 1
  let date2_year = date2.getFullYear()

  if (date1_month === date2_month && date1_year === date2_year) {
    return 0
  } else if (date1 < date2) {
    return -1
  } else {
    return 1
  }
}
