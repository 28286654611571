import {
  CREATE_REGION,
  DELETE_REGION,
  SET_REGION,
  SHOW_REGIONS,
  UPDATE_REGION,
} from '../type'

const initialState = {
  regions: [],
  region: {},
}

const region = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_REGIONS:
      return {
        ...state,
        regions: action.payload,
      }
    case CREATE_REGION:
      return {
        ...state,
        regions: [action.payload, ...state.regions],
      }
    case DELETE_REGION:
      return {
        ...state,
        regions: state.regions.filter((region) => region.id !== action.payload),
      }
    case SET_REGION:
      return {
        ...state,
        region: action.payload,
      }
    case UPDATE_REGION:
      if (JSON.stringify(state.region) !== JSON.stringify(action.payload)) {
        let clone = [...state.regions]
        let index = clone.findIndex((region) => region.id === action.payload.id)
        clone[index] = action.payload
        return {
          ...state,
          regions: clone,
          region: action.payload,
        }
      } else {
        return state
      }
    default:
      return state
  }
}

export default region
