import React from 'react'
import ReactBSAlert from 'react-bootstrap-sweetalert'

const CustomOverrideAlert = ({ onConfirm, onCancel }) => {
  return (
    <ReactBSAlert
      warning
      title="Are you sure?"
      onConfirm={onConfirm}
      onCancel={onCancel}
      showCancel
      confirmBtnBsStyle="warning"
      confirmBtnText="Yes, override it!"
      cancelBtnBsStyle="secondary"
      cancelBtnText="Cancel"
    >
      You won't be able to recover this!
    </ReactBSAlert>
  )
}

export default CustomOverrideAlert
