import { combineReducers } from 'redux'
import status from './status'
import auth from './auth'
import account from './account'
import region from './region'
import district from './district'
import township from './township'
import patient from './patient'
import nurseCounsellor from './nurseCounsellor'
import volunteerHandout from './volunteerHandout'
import formAndRegister from './formAndRegister'
import report from './report'

const reducers = combineReducers({
  status,
  auth,
  account,
  region,
  district,
  township,
  nurseCounsellor,
  patient,
  volunteerHandout,
  formAndRegister,
  report,
})

export default reducers
