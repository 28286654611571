import { call, setAccessToken } from '../../services/api'
import { serverErrorMessage } from '../../utils/messages'
import {
  CREATE_PATIENT,
  DELETE_PATIENT,
  SERIAL_NUMBER,
  SET_LOADING,
  SET_PATIENT,
  SET_SUCCESS,
  SHOW_FOLLOW_UP_PATIENTS,
  SHOW_PATIENTS,
  UPDATE_PATIENT,
} from '../type'
import { NotificationManager } from 'react-notifications'

export const getPatients = (query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      const result = await call(
        'get',
        `patients?${new URLSearchParams(query).toString()}`,
      )

      dispatch({
        type: SHOW_PATIENTS,
        payload: result,
      })
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const getFollowUpPatients = (query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      const result = await call(
        'get',
        `follow-up-patients?${new URLSearchParams(query).toString()}`,
      )

      dispatch({
        type: SHOW_FOLLOW_UP_PATIENTS,
        payload: result,
      })
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const createPatient = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_SUCCESS, payload: false })
    try {
      const response = await call('post', 'patients', data)
      const result = response.data

      dispatch({
        type: CREATE_PATIENT,
        payload: result,
      })
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      })
      NotificationManager.success('Patient has been created successfully!')
    } catch (error) {
      const { status, data } = error.response
      if (status === 400) {
        const obj = data.data
        NotificationManager.error(obj[Object.keys(obj)[0]])
      } else if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      })
    }
  }
}

export const getSerialNumber = (township_id, year) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    dispatch({ type: SET_SUCCESS, payload: false })
    try {
      const result = await call('get', `serial-number/${township_id}/${year}`)

      dispatch({
        type: SERIAL_NUMBER,
        payload: result,
      })
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const getPatient = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    dispatch({ type: SET_SUCCESS, payload: false })
    try {
      const response = await call('get', `patients/${id}`)
      const result = response.data

      dispatch({
        type: SET_PATIENT,
        payload: result,
      })
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const updatePatient = (id, data, path, message) => {
  return async (dispatch) => {
    try {
      const response = await call('post', `${path}/${id}?_method=PUT`, data)
      const result = response.data

      dispatch({
        type: UPDATE_PATIENT,
        payload: result,
      })
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      })
      NotificationManager.success(`${message} has been updated successfully!`)
    } catch (error) {
      const { status, data } = error.response
      if (status === 400) {
        const obj = data.data
        NotificationManager.error(obj[Object.keys(obj)[0]])
      } else if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      })
    }
  }
}

export const recoverPatient = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      await call('get', `patients-recover/${id}`)

      dispatch({ type: DELETE_PATIENT, payload: id })
      NotificationManager.success('Patient has been recovered successfully!')
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const temDeletePatient = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      await call('get', `patients-delete/${id}`)

      dispatch({ type: DELETE_PATIENT, payload: id })
      NotificationManager.success('Patient has been deleted successfully!')
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const deletePatient = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      await call('delete', `patients/${id}`)

      dispatch({ type: DELETE_PATIENT, payload: id })
      NotificationManager.success('Patient has been deleted successfully!')
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const clearMonthPatient = (id, path, message) => {
  return async (dispatch) => {
    try {
      const response = await call('get', `${path}/${id}`)
      const result = response.data

      dispatch({
        type: UPDATE_PATIENT,
        payload: result,
      })
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      })
      NotificationManager.success(`${message} has been cleared successfully!`)
    } catch (error) {
      const { status, data } = error.response
      if (status === 400) {
        const obj = data.data
        NotificationManager.error(obj[Object.keys(obj)[0]])
      } else if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      })
    }
  }
}