import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { Button, Card, CardHeader, Container, Row, Col } from 'reactstrap'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import CustomPagination from 'components/Pagination/Pagination'
import CustomTable from 'components/Table/Table'
import { connect } from 'react-redux'
import {
  getNurseCounsellors,
  getNurseCounsellor,
  getTownships,
} from 'store/actions'
import FullScreenLoading from 'components/FullScreenLoading/FullScreenLoading'
import { paginationLimit } from 'variables/limits'
import {
  nurseCounsellorMiddleware,
  isSpecifiedTownship,
  CREATE,
  EXPORT,
} from 'utils/middleware'
import { NotificationManager } from 'react-notifications'
import { call } from 'services/api'
import { excelExport } from 'utils/excelExport'
import BackBtn from 'utils/backBtn'
import NurseCounsellorCreate from './NurseCounsellorCreate'
import NurseCounsellorDetail from './NurseCounsellorDetail'

const NurseCounsellorList = ({
  status,
  auth,
  nurseCounsellor,
  township,
  getNurseCounsellors,
  getNurseCounsellor,
  getTownships,
}) => {
  const { role } = auth.user
  const router = useHistory()

  const [exportLoading, setExportLoading] = useState(false)
  const [isCreate, setIsCreate] = useState(false)
  const [isDetail, setIsDetail] = useState(false)
  const [selectedTownship, setSelectedTownship] = useState(null)

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }))

  const handleFilter = () => {
    const query = queryString.parse(router.location.search)
    query.page = 1
    if (selectedTownship) {
      query.township_id = selectedTownship.value
    } else {
      delete query['township_id']
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`)
  }

  useEffect(() => {
    const query = queryString.parse(router.location.search)
    if (!('page' in query)) {
      query.page = 1
    }
    getNurseCounsellors(query)
  }, [getNurseCounsellors, router.location.search])

  useEffect(() => {
    if (role !== undefined && !isSpecifiedTownship(role)) {
      getTownships()
    }
  }, [getTownships, role])

  if (status.loading) {
    return <FullScreenLoading />
  }

  const handleExport = async () => {
    setExportLoading(true)
    try {
      const query = queryString.parse(router.location.search)
      const response = await call(
        'get',
        `nurse-counsellors-export?${new URLSearchParams(query).toString()}`,
      )
      const result = response.data.map((data, index) => ({
        ID: index + 1,
        Name: data.name,
        Code: data.code,
        Plain_Password: data.plain_password,
        Gender: data.gender,
        DOB: data.DOB,
        Address: data.address,
        Phone: data.phone,
        Email: data.email,
        Village: data.village,
        Ward: data.ward,
        Education_Status: data.education_status,
        Township: data.township.name,
        Past_History_of_TB: data.past_history_of_TB,
        Previous_Volunteer_Experience: data.previous_volunteer_experience,
        Occuption: data.occuption,
        Phone_Supported: data.phone_supported,
        Remark: data.remark,
      }))

      let fileName = 'Nurse-Counsellors'
      if (selectedTownship) {
        fileName += `-${selectedTownship.label}`
      }

      if (response.status === 'success') {
        excelExport(result, fileName)
      }
    } catch (_) {
      NotificationManager.error('Please try again!')
    }
    setExportLoading(false)
  }

  return (
    <>
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Nurse Counsellors</h3>
              </Col>
              <Col className="text-right" xs="6">
                {nurseCounsellorMiddleware(role, CREATE) && (
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => setIsCreate(true)}
                  >
                    New
                  </Button>
                )}
                {nurseCounsellorMiddleware(role, EXPORT) && (
                  <Button
                    size="sm"
                    color="info"
                    onClick={handleExport}
                    disabled={exportLoading}
                  >
                    {exportLoading ? 'Loading' : 'Export'}
                  </Button>
                )}
              </Col>
            </Row>
            <Row>
              {!isSpecifiedTownship(role) && (
                <>
                  <Col sm={4}>
                    <Select
                      className="mt-3"
                      options={townships}
                      value={selectedTownship}
                      onChange={(value) => setSelectedTownship(value)}
                      placeholder="Filter by township..."
                      isSearchable={true}
                      isClearable={true}
                    />
                  </Col>
                  <Col sm={4}>
                    <Button
                      size="sm"
                      color="success"
                      onClick={handleFilter}
                      className="mt-3"
                    >
                      <i className="fa fa-search" />
                    </Button>
                  </Col>
                </>
              )}
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th className="fixed-cell left" scope="col">
                  ID
                </th>
                <th className="fixed-cell left" scope="col">
                  Name
                </th>
                <th scope="col">Code</th>
                <th scope="col">Password</th>
                <th scope="col">Address</th>
                <th scope="col">Phone</th>
                <th scope="col">Township</th>
                <th scope="col">Action</th>
              </tr>
            }
            body={nurseCounsellor.nurseCounsellors.map(
              (nurseCounsellor, index) => (
                <tr key={nurseCounsellor.id}>
                  <td className="fixed-cell left">{index + 1}</td>
                  <td className="fixed-cell left">{nurseCounsellor.name}</td>
                  <td>{nurseCounsellor.code}</td>
                  <td>{nurseCounsellor.plain_password}</td>
                  <td>{nurseCounsellor.address}</td>
                  <td>{nurseCounsellor.phone}</td>
                  <td>{nurseCounsellor.township.name}</td>
                  <td>
                    <Button
                      size="sm"
                      color="success"
                      onClick={async () => {
                        await getNurseCounsellor(nurseCounsellor.id)
                        setIsDetail(true)
                      }}
                    >
                      Detail
                    </Button>
                  </td>
                </tr>
              ),
            )}
          />

          {nurseCounsellor.total > paginationLimit && (
            <CustomPagination
              pageCount={nurseCounsellor.total / paginationLimit}
            />
          )}
        </Card>
      </Container>

      <NurseCounsellorDetail isOpen={isDetail} toggle={setIsDetail} />
      <NurseCounsellorCreate isOpen={isCreate} toggle={setIsCreate} />
    </>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  township: store.township,
  nurseCounsellor: store.nurseCounsellor,
})

export default connect(mapStateToProps, {
  getNurseCounsellors,
  getNurseCounsellor,
  getTownships,
})(NurseCounsellorList)
