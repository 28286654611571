import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
} from "reactstrap";
import { currentDate } from "utils/currentDate";
import CustomDateInput from "components/Inputs/CustomDateInput";
import CustomSelect from "components/Inputs/CustomSelect";
import { sex } from "variables/options";
import CustomNumberInput from "components/Inputs/CustomNumberInput";
import { typeOfTBDependingOnBACStatus } from "variables/options";
import CustomAlert from "components/Alert/CustomAlert";
import { yesOrNo } from "variables/options";
import { patientMiddleware } from "utils/middleware";
import { UPDATE } from "utils/middleware";
import { connect } from "react-redux";
import CustomInput from "components/Inputs/CustomInput";

import {
  updatePatient,
  getSerialNumber,
  getPatient,
  temDeletePatient,
} from "../../../../../store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { TBCodeRegistration } from "variables/options";
import { years } from "variables/options";
import { isSpecifiedTownship } from "utils/middleware";
import { useHistory, useParams } from "react-router";
import CustomOverrideAlert from "components/Alert/CustomOverrideAlert";
import { regimenTypes, ethnicGroupOptions } from "variables/options";

const PresumptiveInfo = ({
  auth,
  status,
  patient,
  township,
  updatePatient,
  getSerialNumber,
  getPatient,
  temDeletePatient,
  nurseCounsellor,
}) => {
  const { role } = auth.user;
  const { id } = useParams();
  const router = useHistory();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedNurseCounsellor, setSelectedNurseCounsellor] = useState(null);
  const [selectedTBCodeRegistration, setSelectedTBCodeRegistration] =
    useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedSex, setSelectedSex] = useState(null);
  const [
    selectedTypeOfTBDependingOnBACStatus,
    setSelectedTypeOfTBDependingOnBACStatus,
  ] = useState(null);
  const [selectedTransfer, setSelectedTransfer] = useState(null);
  const [selectedTownship, setSelectedTownship] = useState(null);
  const [selectedRegimenType, setSelectedRegimenType] = useState(null);
  const [selectedEthnicGroup, setSelectedEthnicGroup] = useState(null);

  useEffect(() => {
    getPatient(id);
  }, [getPatient, id]);

  const nurseCounsellors = nurseCounsellor.nurseCounsellors.map((element) => ({
    value: element.id,
    label: `${element.name}(${element.township?.abbreviation})`,
  }));

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  useEffect(() => {
    const {
      nurse_counsellor,
      TB_code_registration,
      year,
      serial_number,
      date_of_registration,
      treatment_start_date,
      union_temporary_TB_code,
      regimen_type,
      name,
      sex,
      ethnic_group,
      other_ethnic_group,
      age,
      address,
      phone,
      transfer_in,
      township,
      type_of_TB_depending_on_bac_status,
    } = patient.patient;
    setValue(
      "nurse_counsellor",
      nurse_counsellor === null
        ? null
        : {
            value: nurse_counsellor?.id,
            label: `${nurse_counsellor?.name}(${nurse_counsellor?.township?.abbreviation})`,
          }
    );
    setSelectedNurseCounsellor(
      nurse_counsellor === null
        ? null
        : {
            value: nurse_counsellor?.id,
            label: `${nurse_counsellor?.name}(${nurse_counsellor?.township?.abbreviation})`,
          }
    );
    setValue(
      "TB_code_registration",
      TB_code_registration === null
        ? null
        : { value: TB_code_registration, label: TB_code_registration }
    );
    setSelectedTBCodeRegistration(
      TB_code_registration === null
        ? null
        : { value: TB_code_registration, label: TB_code_registration }
    );
    setValue("year", year === null ? null : { value: year, label: year });
    setSelectedYear(year === null ? null : { value: year, label: year });
    setValue("serial_number", serial_number);
    setValue("date_of_registration", date_of_registration);
    setValue("treatment_start_date", treatment_start_date);
    setValue("union_temporary_TB_code", union_temporary_TB_code);
    setValue("name", name);
    setValue("sex", sex === null ? null : { value: sex, label: sex });
    setSelectedSex(sex === null ? null : { value: sex, label: sex });
    setValue(
      "ethnic_group",
      ethnic_group === null
        ? null
        : { value: ethnic_group, label: ethnic_group }
    );
    setValue("other_ethnic_group", other_ethnic_group);
    setSelectedEthnicGroup(
      ethnic_group === null
        ? null
        : { value: ethnic_group, label: ethnic_group }
    );
    setValue("age", age);
    setValue("address", address);
    setValue("phone", phone);
    setValue(
      "transfer_in",
      transfer_in === null ? null : { value: transfer_in, label: transfer_in }
    );
    setSelectedTransfer(
      transfer_in === null ? null : { value: transfer_in, label: transfer_in }
    );
    setValue(
      "township",
      township === null ? null : { value: township?.id, label: township?.name }
    );
    setSelectedTownship(
      township === null ? null : { value: township?.id, label: township?.name }
    );
    setValue(
      "type_of_TB_depending_on_bac_status",
      type_of_TB_depending_on_bac_status === null
        ? null
        : {
            value: type_of_TB_depending_on_bac_status,
            label: type_of_TB_depending_on_bac_status,
          }
    );
    setSelectedTypeOfTBDependingOnBACStatus(
      type_of_TB_depending_on_bac_status === null
        ? null
        : {
            value: type_of_TB_depending_on_bac_status,
            label: type_of_TB_depending_on_bac_status,
          }
    );
    setValue(
      "regimen_type",
      regimen_type === null
        ? null
        : { value: regimen_type, label: regimen_type }
    );
    setSelectedRegimenType(
      regimen_type === null
        ? null
        : { value: regimen_type, label: regimen_type }
    );
  }, [patient.patient, setValue]);

  const onSubmit = async (data) => {
    setLoading(true);
    await updatePatient(
      patient.patient?.id,
      {
        nurse_counsellor_id: selectedNurseCounsellor?.value,
        TB_code_registration: selectedTBCodeRegistration.value,
        year: selectedYear.value,
        serial_number: data.serial_number,
        date_of_registration: data.date_of_registration,
        treatment_start_date: data.treatment_start_date,
        union_temporary_TB_code: data.union_temporary_TB_code,
        name: data.name,
        sex: selectedSex.value,
        ethnic_group: selectedEthnicGroup?.value,
        other_ethnic_group:
          selectedEthnicGroup?.value === "Others"
            ? data.other_ethnic_group
            : null,

        age: data.age,
        address: data.address,
        phone: data.phone,
        transfer_in: selectedTransfer.value,
        township_id: selectedTownship.value,
        type_of_TB_depending_on_bac_status:
          selectedTypeOfTBDependingOnBACStatus.value,
        regimen_type: selectedRegimenType.value,
      },
      "patients",
      "Presumptive Info"
    );
    setLoading(false);
  };

  const handleOverride = (data) => {
    setAlert(
      <CustomOverrideAlert
        onConfirm={async () => {
          setAlert(null);
          setLoading(true);
          await updatePatient(
            patient.patient?.id,
            {
              nurse_counsellor_id: selectedNurseCounsellor?.value,
              TB_code_registration: selectedTBCodeRegistration.value,
              year: selectedYear.value,
              serial_number: data.serial_number,
              date_of_registration: data.date_of_registration,
              treatment_start_date: data.treatment_start_date,
              union_temporary_TB_code: data.union_temporary_TB_code,
              name: data.name,
              sex: selectedSex.value,
              age: data.age,
              address: data.address,
              phone: data.phone,
              ethnic_group: selectedEthnicGroup?.value,
              other_ethnic_group:
                selectedEthnicGroup?.value === "Others"
                  ? data.other_ethnic_group
                  : null,
              transfer_in: selectedTransfer.value,
              township_id: selectedTownship.value,
              type_of_TB_depending_on_bac_status:
                selectedTypeOfTBDependingOnBACStatus.value,
              regimen_type: selectedRegimenType.value,
            },
            "override",
            "Presumptive Info"
          );
          setLoading(false);
        }}
        onCancel={() => setAlert(null)}
        toTrash={true}
      />
    );
  };

  const handleDelete = () => {
    setAlert(
      <CustomAlert
        onConfirm={async () => {
          await temDeletePatient(patient.patient.id);
          setAlert(null);
          router.push(`/admin/transferred-patient-list`);
        }}
        onCancel={() => setAlert(null)}
        toTrash={true}
      />
    );
  };
  useEffect(() => {
    setValue("serial_number", patient.serialNumber);
  }, [patient.serialNumber, setValue]);

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <CardHeader className="border-0">
              <h3>TB Patient(TI) Information</h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={4}>
                  <CustomSelect
                    id="year"
                    label="Year"
                    rules={{ required: "Year is required!" }}
                    control={control}
                    options={years}
                    value={selectedYear}
                    setData={(data) => {
                      setSelectedYear(data);
                      setSelectedTownship(null);
                      setValue("township", null);
                    }}
                    placeholder="Year"
                  />
                </Col>
                <Col md={4}>
                  {role !== undefined && !isSpecifiedTownship(role) ? (
                    <CustomSelect
                      id="township"
                      label="Township"
                      rules={{ required: "Township is required!" }}
                      control={control}
                      options={townships}
                      value={selectedTownship}
                      setData={(data) => {
                        setSelectedTownship(data);
                        getSerialNumber(data?.value, selectedYear?.value);
                      }}
                      placeholder="Township"
                      isDisabled={selectedYear === null}
                    />
                  ) : (
                    <p>{auth.user?.township?.name}</p>
                  )}
                </Col>
                <Col md={4}>
                  <CustomNumberInput
                    id="serial_number"
                    label="Serial Number"
                    register={{
                      ...register("serial_number", {
                        required: "Serial Number is required!",
                      }),
                    }}
                    placeholder="Enter Serial Number"
                    errors={errors}
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <CustomSelect
                    id="nurse_counsellor"
                    label="Nurse Counsellor"
                    control={control}
                    options={nurseCounsellors}
                    value={selectedNurseCounsellor}
                    setData={setSelectedNurseCounsellor}
                    placeholder="Nurse Counsellor"
                    isRequired={false}
                  />
                </Col>
                <Col md={4}>
                  <CustomSelect
                    id="TB_code_registration"
                    label="TB Code Registration"
                    rules={{ required: "TB Code Registration is required!" }}
                    control={control}
                    options={TBCodeRegistration}
                    value={selectedTBCodeRegistration}
                    setData={setSelectedTBCodeRegistration}
                    placeholder="TB Code Registration"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <CustomDateInput
                    id="date_of_registration"
                    label="Date Of Registration"
                    register={{
                      ...register("date_of_registration", {
                        required: "Date Of Registration is required!",
                      }),
                    }}
                    placeholder="Select Date Of Registration"
                    errors={errors}
                    isRequired={true}
                    max={currentDate()}
                  />
                </Col>
                <Col md={4}>
                  <CustomDateInput
                    id="treatment_start_date"
                    label="Treatment Start Date"
                    register={{
                      ...register("treatment_start_date", false),
                    }}
                    placeholder="Select Treatment Start Date"
                    errors={errors}
                    isRequired={false}
                    max={currentDate()}
                  />
                </Col>
                <Col md={4}>
                  <CustomInput
                    id="union_temporary_TB_code"
                    label="TB Code"
                    register={{
                      ...register("union_temporary_TB_code", {
                        required: true,
                      }),
                    }}
                    placeholder="Enter TB Code"
                    errors={errors}
                    isRequired={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <CustomInput
                    id="name"
                    label="Name"
                    register={{
                      ...register("name", {
                        required: "Name is required!",
                      }),
                    }}
                    placeholder="Enter Name"
                    errors={errors}
                  />
                </Col>
                <Col md={4}>
                  <CustomSelect
                    id="sex"
                    label="Sex"
                    rules={{ required: "Sex is required!" }}
                    control={control}
                    options={sex}
                    value={selectedSex}
                    setData={setSelectedSex}
                    placeholder="Sex"
                  />
                </Col>
                <Col md={4}>
                  <CustomSelect
                    id="ethnic_group"
                    label="Ethnic group"
                    rules={{ required: false }}
                    control={control}
                    options={ethnicGroupOptions}
                    value={selectedEthnicGroup}
                    setData={setSelectedEthnicGroup}
                    placeholder="Ethnic Group"
                    isRequired={false}
                  />
                </Col>
                {selectedEthnicGroup?.value === "Others" && (
                  <Col md={4}>
                    <CustomInput
                      id="other_ethnic_group"
                      label="Other ethnic group"
                      register={{
                        ...register("other_ethnic_group", {
                          required: "Ethnic Group is required",
                        }),
                      }}
                      placeholder="Enter Other ethnic group"
                      errors={errors}
                      isRequired={true}
                    />
                  </Col>
                )}
                <Col md={4}>
                  <CustomNumberInput
                    id="age"
                    label="Age"
                    register={{
                      ...register("age", {
                        required: "Age is required!",
                      }),
                    }}
                    placeholder="Enter age"
                    errors={errors}
                    max={120}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <CustomInput
                    id="address"
                    label="Address"
                    register={{
                      ...register("address", {
                        required: false,
                      }),
                    }}
                    placeholder="Enter Address"
                    errors={errors}
                    isRequired={false}
                  />
                </Col>
                <Col md={4}>
                  <CustomInput
                    id="phone"
                    label="Phone"
                    register={{
                      ...register("phone", {
                        required: "Phone is required!",
                      }),
                    }}
                    placeholder="Enter Phone"
                    errors={errors}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <CustomSelect
                    id="type_of_TB_depending_on_bac_status"
                    label="Type of TB depending on Bac Status"
                    rules={{
                      required:
                        "Type of TB depending on Bac Status is required!",
                    }}
                    control={control}
                    options={typeOfTBDependingOnBACStatus}
                    value={selectedTypeOfTBDependingOnBACStatus}
                    setData={setSelectedTypeOfTBDependingOnBACStatus}
                    placeholder="Type of TB depending on Bac Status"
                  />
                </Col>
                <Col md={4}>
                  <CustomSelect
                    id="regimen_type"
                    label="Regimen Type"
                    rules={{
                      required: "Regimen Type is required!",
                    }}
                    control={control}
                    options={regimenTypes}
                    value={selectedRegimenType}
                    setData={setSelectedRegimenType}
                    placeholder="Regimen Type"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <CustomSelect
                    id="transfer_in"
                    label="Transfer In"
                    rules={{
                      required: "Transfer In is required!",
                    }}
                    control={control}
                    options={yesOrNo}
                    value={selectedTransfer}
                    setData={setSelectedTransfer}
                    placeholder="Transfer"
                    isDisabled={true}
                  />
                </Col>
              </Row>
            </CardBody>
            {patientMiddleware(role, UPDATE) && (
              <CardFooter className="text-right">
                <Button
                  color="primary"
                  size="sm"
                  disabled={loading}
                  type="submit"
                >
                  {loading ? "Loading" : "Update"}
                </Button>
                <Button color="danger" size="sm" onClick={handleDelete}>
                  Delete
                </Button>
                <Button
                  color="warning"
                  size="sm"
                  disabled={loading}
                  onClick={handleSubmit(handleOverride)}
                >
                  {loading ? "Loading" : "Clear"}
                </Button>
              </CardFooter>
            )}
          </Card>
        </Form>
      </Container>
    </>
  );
};
const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  patient: store.patient,
  township: store.township,
  nurseCounsellor: store.nurseCounsellor,
});

export default connect(mapStateToProps, {
  updatePatient,
  getSerialNumber,
  getPatient,
  temDeletePatient,
})(PresumptiveInfo);
