import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Form } from 'reactstrap'
import CustomInput from 'components/Inputs/CustomInput'
import CustomSelect from 'components/Inputs/CustomSelect'
import CustomModal from 'components/Modal/CustomModal'
import { connect } from 'react-redux'
import { updateAccount } from 'store/actions'
import { roles } from 'variables/options'

const AccountUpdate = ({
  isOpen,
  toggle,
  status,
  account,
  township,
  updateAccount,
}) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  const [loading, setLoading] = useState(false)
  const [role, setRole] = useState(null)
  const [selectedTownship, setSelectedTownship] = useState(null)

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }))

  useEffect(() => {
    const { name, role, township } = account.account

    setValue('name', name)
    setValue('role', { value: role, label: role })
    setRole({ value: role, label: role })
    setValue('township', { value: township?.id, label: township?.name })
    setSelectedTownship({ value: township?.id, label: township?.name })
  }, [setValue, account.account])

  const onSubmit = async (data) => {
    setLoading(true)
    await updateAccount(account.account.id, {
      name: data.name,
      role: role.value,
      township_id: selectedTownship?.value,
    })
    setLoading(false)
  }

  useEffect(() => {
    if (status.success) {
      toggle(false)
    }
  }, [status.success, toggle])

  return (
    <CustomModal
      isOpen={isOpen}
      title="Update Account"
      onClick={() => toggle(false)}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <CustomInput
            id="name"
            label="Name"
            register={{
              ...register('name', {
                required: 'Name is required!',
              }),
            }}
            placeholder="Enter name"
            errors={errors}
          />
          <CustomSelect
            id="role"
            label="Select Role"
            rules={{ required: 'Role is required!' }}
            control={control}
            options={roles}
            value={role}
            setData={(data) => {
              setSelectedTownship(null)
              setRole(data)
            }}
            placeholder="Select role"
          />
          {(role?.value === "Field Officer" || role?.value === 'Nurse Counsellor') && (
            <CustomSelect
              id="township"
              label="Select Township"
              rules={{
                required:
                (role?.value === "Field Officer" || role?.value === 'Nurse Counsellor')
                    ? 'Township is required!'
                    : false,
              }}
              control={control}
              options={townships}
              value={selectedTownship}
              setData={setSelectedTownship}
              placeholder="Select township"
            />
          )}
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? 'Loading' : 'Update'}
          </Button>
        </div>
      </Form>
    </CustomModal>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  account: store.account,
  township: store.township,
})

export default connect(mapStateToProps, { updateAccount })(AccountUpdate)
