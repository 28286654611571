import { call, setAccessToken } from '../../services/api'
import { serverErrorMessage } from '../../utils/messages'
import { SET_LOADING, MONTHLY_REPORT, OUTCOME_REPORT } from '../type'
import { NotificationManager } from 'react-notifications'

export const getMonthlyReport = (query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      const result = await call(
        'get',
        `monthly-report?${new URLSearchParams(query).toString()}`,
      )

      dispatch({
        type: MONTHLY_REPORT,
        payload: result,
      })
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const getOutcomeReport = (query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      const result = await call(
        'get',
        `outcome-report?${new URLSearchParams(query).toString()}`,
      )

      dispatch({
        type: OUTCOME_REPORT,
        payload: result,
      })
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}
