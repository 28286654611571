import { MONTHLY_REPORT, OUTCOME_REPORT } from '../type'

const initialState = {
  monthlyReport: {},
  outcomeReport: {},
}

const report = (state = initialState, action) => {
  switch (action.type) {
    case MONTHLY_REPORT:
      return {
        ...state,
        monthlyReport: action.payload.data,
      }
    case OUTCOME_REPORT:
      return {
        ...state,
        outcomeReport: action.payload.data,
      }
    default:
      return state
  }
}

export default report
