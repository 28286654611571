export const skipMonth = (smokingStatus, smokingInsideHome) => {
  if (smokingStatus === 'No' && smokingInsideHome === 'No') {
    return true
  } else if (smokingStatus === 'Yes' && smokingInsideHome === 'No') {
    return false
  } else if (smokingStatus === 'No' && smokingInsideHome === 'Yes') {
    return false
  } else {
    return false
  }
}

export const townshipAcronyms = {
  AMP: 'Amarapura',
  PTG: 'Patheingyi',
  PGT: 'Pyigyitagon',
  MGN: 'Myingyan',
}

export const handleVillageAndWard = (township) => {
  if (
    township === townshipAcronyms.AMP ||
    township === townshipAcronyms.PTG ||
    township === townshipAcronyms.PGT ||
    township === townshipAcronyms.MGN
  ) {
    return true
  } else {
    return false
  }
}
